import React, { useState, useEffect, createRef, useRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Checkbox, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField, ThemeProvider } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { AccountCircleOutlined, Add, AllInbox, ArrowDownward, ArrowUpward, Close, Delete, Edit, FastfoodOutlined, ImageOutlined, Inbox, LocationOn, LockOutlined, MailOutline, Map, RotateLeft, Save, Search, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons'
import { blue, red } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/styles'
import DialogBoolean from '../../components/dialog/DialogBoolean'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { fileFormatV, joinV, minLengthV, minNumberV, notNullV } from '../../helpers/validation'

const MyTextInput = styled(TextField)(() => ({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#555",
    color: "#555"
  }
}))

const MyFormControl = styled(FormControl)(() => ({
  "& .MuiInputBase-root.Mui-disabled": {
    WebkitTextFillColor: "#555",
    color: "#555"
  }
}))

const Detail = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const nameRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('asd');
  const [alertType, setAlertType] = useState('error');

  const [showDialogDelete, setShowDialogDelete] = useState(false);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [parentCategoriList, setParentCategoriList] = useState([]);
  const [categoriList, setCategoriList] = useState([]);

  const [id, setId] = useState();
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [name, setName] = useState('');
  const [parentCategori, setParentCategori] = useState('-1');
  const [categori, setCategori] = useState('');
  const [harga, setHarga] = useState('');
  const [berat, setBerat] = useState('');
  const [deskripsi, setDeskripsi] = useState();
  // const [tampilDiDepan, setTampilDiDepan] = useState('');
  const [status, setStatus] = useState('');
  const [stok, setStok] = useState('');
  const [maxBeli, setMaxBeli] = useState('');
  const [minBeli, setMinBeli] = useState('');

  const [validation, setValidation] = useState({
    image: {},
    name: {},
    parentCategori: {},
    categori: {},
    harga: {},
    berat: {},
    // tampilDiDepan: {},
    status: {},
    stok: {},
    maxBeli: {},
    minBeli: {},
  })

  useEffect(() => {
    if (!isReadOnly) {
      nameRef.current.focus();
    }
  }, [isReadOnly])
  

  useEffect(() => {
    getPassingData();
    getAllData();
  }, []);

  function getPassingData() {
    setId(location?.state?.id);
    setName(location?.state?.name);
    setImage(location?.state?.imageP);
    setImagePreview(location?.state?.image);
    setHarga(location?.state?.price);
    setBerat(location?.state?.weight);
    setParentCategori(location?.state?.category_parent_id+"");
    setCategori(location?.state?.category_id+"");
    setDeskripsi(location?.state?.description);
    // setTampilDiDepan(location?.state?.in_front);
    setStatus(location?.state?.active);
    setStok(location?.state?.stock);
    setMaxBeli(location?.state?.max_buy);
    setMinBeli(location?.state?.min_buy);

    //console.log(location?.state);
  }

  async function getCategori() {
    await axios({
      method: "get",
      url: "/api/category/egrocery/",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('Categori:', response.data);
        setParentCategoriList(response.data.values.data);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  async function getSubCategori(parent_id) {
    await axios({
      method: "get",
      url: "/api/category/egrocery/"+parent_id,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('Sub Categori:', response.data);
        setCategoriList(response.data.values.data);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  function getAllData() {
    setIsLoading(true);
    Promise.all([getCategori(), getSubCategori(location?.state?.category_parent_id)]).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    })
  }


  function resetData() {
    setIsReadOnly(true);
    setImage();
    getPassingData();
    getAllData();
  }

  function handleSubmit() {
    // e.preventDefault();
    // if (
    //   name === "" ||
    //   parentCategori === "" ||
    //   categori === "" ||
    //   deskripsi === "" ||
    //   harga === ""
    // ) {
    //   setAlertType('error');
    //   setAlertMessage('Lengkapi data');
    //   setShowAlert(true);
    //   return false;
    // }

    let vt = {
      image: fileFormatV(image, ['png', 'jpg', 'jpeg']),
      name: minLengthV(name, 3),
      parentCategori: notNullV(parentCategori),
      categori: notNullV(categori),
      harga: joinV([minNumberV(harga, 0), notNullV(harga)]),
      berat: joinV([minNumberV(berat, 0), notNullV(berat)]),
      status: notNullV(status),
      stok: joinV([minNumberV(stok, 0), notNullV(stok)]),
      maxBeli: joinV([minNumberV(maxBeli, 1), notNullV(maxBeli)]),
      minBeli: joinV([minNumberV(minBeli, 1), notNullV(minBeli)]),
    }
    setValidation(vt);
    if (vt.image.error || vt.name.error || vt.categori.error || vt.parentCategori.error || vt.harga.error || vt.berat.error || vt.status.error || vt.stok.error || vt.maxBeli.error || vt.minBeli.error) {
      return false;
    }

    setIsLoading(true);
    let data = new FormData();
    if (image) {
      data.append("image", image);
    }
    data.append("description", deskripsi);
    data.append("price", harga);
    data.append("name", name);
    data.append("category_id", categori);
    // data.append("in_front", tampilDiDepan);
    data.append("weight", berat);
    data.append("max_buy", maxBeli);
    data.append("min_buy", minBeli);
    data.append("stock", stok);
    data.append("sort", "");
    data.append("active", status);

    axios({
      method: "put",
      url: `/api/products/${id}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
      data
    })
      .then((response) => {
        //console.log(response.data);
        if (response.data.values.success) {
          setAlertType('success');
          setAlertMessage('Perubahan berhasil disimpan');
          setShowAlert(true);
          setIsReadOnly(true);
          
          let updateState = {
            active: status,
            category_id: categori,
            category_parent_id: parentCategori,
            description: deskripsi,
            id: id,
            max_buy: maxBeli,
            min_buy: minBeli,
            name: name,
            price: harga,
            stock: stok,
            weight: berat,
          };
          if (image) {
            updateState.imageP = image;
          } else {
            updateState.image = location?.state?.image;
          }
          history.replace(location.pathname, updateState);
        } else {
          setAlertType('error');
          setAlertMessage(response.data.values.message);
          setShowAlert(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        //console.log(error.response);
        setAlertType('error');
        setAlertMessage(error?.response?.data?.values?.message ? error?.response?.data?.values?.message : 'Terjadi Kesalahan');
        setShowAlert(true);
        setIsLoading(false);
      });
  };

  async function handleDelete() {
    setIsLoading(true);
    await axios({
      method: "delete",
      url: `/api/products/${id}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
    })
      .then(response => {
        setIsLoading(false);
        history.push({
          pathname: '/egrocery/produk',
          state: {snackbar_message: 'Data berhasil dihapus', snackbar_type: 'success'}
        });
      })
      .catch(error => {
        //console.log(error.response);
        setAlertType('error');
        setAlertMessage(error?.response?.data?.values?.message ? error?.response?.data?.values?.message : 'Terjadi Kesalahan');
        setShowAlert(true);
      });
  }
  
  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <DialogBoolean 
        open={showDialogDelete} 
        handleClose={() => setShowDialogDelete(false)} 
        title="Peringatan!" 
        content="Apakah anda yakin akan menghapus?"
        onClickYes={handleDelete}
      />

      {/* <form onSubmit={handleSubmit}> */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={10} xl={8} className="d-flex flex-column flex-sm-row">
            <h3>Detail</h3>
            <div className='ml-auto d-flex'>
              {isReadOnly && (
                <Button variant='contained' size='small' className='bg-primary text-white align-items-center' onClick={() => setIsReadOnly(false)}>
                  <Edit /> Edit
                </Button>
              )}
              <Button variant='contained' size='small' className='bg-danger text-white align-items-center ml-2' onClick={() => setShowDialogDelete(true)}>
                <Delete /> Hapus
              </Button>
            </div>
          </Grid>
          <div className="d-none d-xl-flex w-100" />
          <Grid item xs={12} sm={6} md={5} xl={4}>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
            <h4 className='mb-3'>Gambar</h4>
            <div className='d-flex flex-column align-items-stretch'>
              <img src={image ? URL.createObjectURL(image) : imagePreview ? imagePreview : ''} style={{maxHeight: 300, maxWidth: '80%'}} className="mb-3 mx-3 align-self-center" alt="gambar"/>
              {!isReadOnly && (
                <MyTextInput
                  type="file" 
                  name="image" 
                  variant="outlined" 
                  label="Gambar" 
                  className="mb-3"
                  disabled={isReadOnly}
                  onChange={(e) => setImage(e.target.files[0])}
                  error={validation?.image?.error}
                  helperText={validation?.image?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ImageOutlined color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </div>
            </Paper>

            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
              <h4 className='mb-3'>Produk</h4>
              <MyTextInput
                inputRef={nameRef}
                type="text" 
                name="name" 
                variant="outlined" 
                label="Nama" 
                className="mb-3"
                value={name}
                disabled={isReadOnly}
                onChange={(e) => setName(e.target.value)}
                error={validation?.name?.error}
                helperText={validation?.name?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FastfoodOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly} error={validation?.parentCategori?.error}>
                <InputLabel id="categori_label">Kelompok</InputLabel>
                <Select
                  labelId="categori_label"
                  label="Kelompok"
                  name="categori"
                  value={parentCategori}
                  onChange={e => {setParentCategori(e.target.value); setCategori(''); getSubCategori(e.target.value);}}
                >
                  <MenuItem value="-1" disabled>Pilih</MenuItem>
                  {parentCategoriList.map((item, index) => (
                    <MenuItem key={index} value={item.id+""}>{item.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{validation?.parentCategori?.message}</FormHelperText>
              </MyFormControl>
              <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly} error={validation?.categori?.error}>
                <InputLabel id="categori_label">Categori</InputLabel>
                <Select
                  labelId="categori_label"
                  label="Categori"
                  name="categori"
                  value={categori}
                  onChange={e => setCategori(e.target.value)}
                >
                  <MenuItem value="" disabled>Pilih</MenuItem>
                  {categoriList.map((item, index) => (
                    <MenuItem key={index} value={item.id+""}>{item.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{validation?.categori?.message}</FormHelperText>
              </MyFormControl>
              <MyTextInput
                type="number" 
                name="harga" 
                variant="outlined" 
                label="Harga" 
                className="mb-3"
                value={harga}
                disabled={isReadOnly}
                onChange={(e) => setHarga(e.target.value)}
                error={validation?.harga?.error}
                helperText={validation?.harga?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className='text-muted'>
                      Rp
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="number" 
                name="berat" 
                variant="outlined" 
                label="Berat" 
                className="mb-3"
                value={berat}
                disabled={isReadOnly}
                onChange={(e) => setBerat(e.target.value)}
                error={validation?.berat?.error}
                helperText={validation?.berat?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className='text-muted'>
                      <AllInbox color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
              <h4 className='mb-3'>Deskripsi</h4>
              {deskripsi && (
                <CKEditor
                  editor={ ClassicEditor }
                  data={deskripsi}
                  disabled={isReadOnly}
                  onChange={ ( event, editor ) => {
                    setDeskripsi(editor.getData());
                  }}
                />
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={5} xl={4}>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
              <h4 className='mb-3'>Detail</h4>
              <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly} error={validation?.status?.error}>
                <InputLabel id="status_label">Status</InputLabel>
                <Select
                  labelId="status_label"
                  label="Status"
                  name="status"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <MenuItem value="1">Aktif</MenuItem>
                  <MenuItem value="0">Tidak Aktif</MenuItem>
                </Select>
                <FormHelperText>{validation?.status?.message}</FormHelperText>
              </MyFormControl>
              {/* <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly}>
                <InputLabel id="tampil_depan_label">Tampil di depan</InputLabel>
                <Select
                  labelId="tampil_depan_label"
                  label="Tampil di depan"
                  name="tampil_di_depan"
                  value={tampilDiDepan}
                  onChange={e => setTampilDiDepan(e.target.value)}
                >
                  <MenuItem value="1">Ya</MenuItem>
                  <MenuItem value="0">Tidak</MenuItem>
                </Select>
              </MyFormControl> */}
              <MyTextInput
                type="number" 
                name="stok" 
                variant="outlined" 
                label="Stok" 
                className="mb-3"
                value={stok}
                disabled={isReadOnly}
                onChange={(e) => setStok(e.target.value)}
                error={validation?.stok?.error}
                helperText={validation?.stok?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className='text-muted'>
                      <Inbox color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="number" 
                name="max_beli" 
                variant="outlined" 
                label="Max Beli" 
                className="mb-3"
                value={maxBeli}
                disabled={isReadOnly}
                onChange={(e) => setMaxBeli(e.target.value)}
                error={validation?.maxBeli?.error}
                helperText={validation?.maxBeli?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className='text-muted'>
                      <ArrowUpward color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="number" 
                name="min_beli" 
                variant="outlined" 
                label="Min Beli" 
                className="mb-3"
                value={minBeli}
                disabled={isReadOnly}
                onChange={(e) => setMinBeli(e.target.value)}
                error={validation?.minBeli?.error}
                helperText={validation?.minBeli?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className='text-muted'>
                      <ArrowDownward color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <div className="d-flex flex-row justify-content-end mt-3">
                  {!isReadOnly && (
                    <>
                      <Button variant='contained' className='bg-secondary text-white align-items-center mr-1' onClick={() => resetData()}>
                        <RotateLeft /> Cancel
                      </Button>
                      <Button variant='contained' className='bg-success text-white align-items-center' onClick={handleSubmit}>
                        <Save /> Simpan Perubahan
                      </Button>
                    </>
                  )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      {/* </form> */}
    </>
  )
}

export default React.memo(Detail)

import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Checkbox, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { AccountCircleOutlined, Add, Close, Delete, LocationOn, LockOutlined, MailOutline, Map, Save, Search, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';


const Tambah = (props) => {
  const urlParams = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('asd');
  const [alertType, setAlertType] = useState('error');

  const [roleList, setRoleList] = useState([]);
  const [provinsiList, setProvinsiList] = useState([]);
  const [kotaList, setKotaList] = useState([]);
  const [kurirList, setKurirList] = useState([]);

  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [provinsi, setProvinsi] = useState('');
  const [kota, setKota] = useState('');
  const [alamat, setAlamat] = useState('');
  const [kurir, setKurir] = useState({});
  const [idReseller, setIdReseller] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  function kurirHandler(code, value) {
    let temp = {...kurir};
    temp[code] = !value;
    //console.log(temp);
    setKurir(temp);
  }

  useEffect(() => {
    getAllData();
  }, []);

  function getAllData() {
    setIsLoading(true);
    Promise.all([getRole(), getProvince(), getCourier()]).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    })
  }

  async function getRole() {
    await axios({
      method: "get",
      url: "/api/admin/get-role",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('role:', response.data);
        setRoleList(response.data.values);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  async function getCity(id) {
    await axios({
      method: "get",
      url: `/api/admin/get-city?&province=${id}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('kota:', response.data);
        setKotaList(response.data.values.data);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  async function getProvince() {
    await axios({
      method: "get",
      url: "/api/admin/get-province",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
    })
      .then(response => {
        //console.log('Provinsi:', response.data);
        setProvinsiList(response.data.values.data);
      })
      
      .catch(error => {
        //console.log(error);
      });
  }

  async function getCourier() {
    await axios({
      method: "get",
      url: "/api/courier/all",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('Kurir:', response.data);
        setKurirList(response.data.values);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  function handleSubmit() {
    // e.preventDefault();
    if (
      name === "" ||
      email === "" ||
      password === "" ||
      kurir.length == 0 ||
      provinsi === "" ||
      kota === "" ||
      alamat === ""
    ) {
      setAlertType('error');
      setAlertMessage('Lengkapi data');
      setShowAlert(true);
      return false;
    }
    let courier = [];
    let final_cour = "";
    kurirList.forEach(item => {
      if (kurir[item.code]) {
        courier.push(item.code);
      }
    });
    final_cour = courier.join();

    axios({
      method: "post",
      url: "/api/admin/",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
      data: {
        name: name,
        role_id: role,
        email: email,
        password: password,
        active: status,
        city: kota,
        province: provinsi,
        postal_code: 0,
        courier: final_cour,
        address: alamat,
        idreseller: idReseller,
        longitude: longitude,
        latitude: latitude,
      },
    })
      .then((response) => {
        history.push({
          pathname: '/users',
          state: {snackbar_message: 'Data Berhasil Ditambakan', snackbar_type: 'success'}
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      {/* <form onSubmit={handleSubmit}> */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={10} xl={8}>
          <h3>Tambah User</h3>
        </Grid>
        <div className="d-none d-xl-flex w-100" />
        <Grid item xs={12} sm={6} md={5} xl={4}>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
            <h4 className='mb-3'>Data Diri</h4>
            <TextField
                type="text" 
                name="name" 
                variant="outlined" 
                label="Nama" 
                className="mb-3"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            <TextField
                type="email" 
                name="email" 
                variant="outlined" 
                label="Email" 
                className="mb-3"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutline color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            <TextField 
                type={passwordVisible ? 'text' : 'password'} 
                name="password" 
                variant="outlined" 
                label="Password"
                className="mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined color='disabled' />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start" onClick={() => setPasswordVisible(!passwordVisible)} style={{cursor: 'pointer'}}>
                      {passwordVisible ? (
                        <VisibilityOff color='disabled' />
                      ) : (
                        <Visibility color='disabled' />
                      )}
                    </InputAdornment>
                  )
                }}
              />
          </Paper>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
            <h4 className='mb-3'>Alamat</h4>
            <FormControl variant='outlined' className='mb-3'>
              <InputLabel id="provinsi_label">Provinsi</InputLabel>
              <Select
                labelId="provinsi_label"
                label="Provinsi"
                name="provinsi"
                value={provinsi}
                onChange={e => {setProvinsi(e.target.value); getCity(e.target.value);}}
              >
                <MenuItem value="" selected disabled>Pilih Provinsi</MenuItem>
                {provinsiList.map((item, index) => (
                  <MenuItem key={index} value={item.province_id}>{item.province}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant='outlined' className='mb-3'>
              <InputLabel id="kota_label">Kota</InputLabel>
              <Select
                labelId="kota_label"
                label="Kota"
                name="kota"
                placeholder='Pilih Kota/Kabupaten'
                value={kota}
                onChange={e => setKota(e.target.value)}
              >
                <MenuItem value="" selected disabled>Pilih Kota</MenuItem>
                {kotaList.map((item, index) => (
                  <MenuItem key={index} value={item.city_id}>{item.city_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
                type="text" 
                name="alamat" 
                variant="outlined" 
                label="Alamat" 
                className="mb-3"
                multiline={true}
                value={alamat}
                onChange={(e) => setAlamat(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            <TextField
                type="text" 
                name="latitude" 
                variant="outlined" 
                label="Latitude" 
                className="mb-3"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            <TextField
                type="text" 
                name="longitude" 
                variant="outlined" 
                label="Longitude" 
                className="mb-3"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={5} xl={4}>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
            <h4 className='mb-3'>Akun</h4>
            <TextField
                type="text" 
                name="id_reseller" 
                variant="outlined" 
                label="ID Reseller" 
                className="mb-3"
                value={idReseller}
                onChange={(e) => setIdReseller(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKey color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            <FormControl variant='outlined' className='mb-3'>
              <InputLabel id="status_label">Status</InputLabel>
              <Select
                labelId="status_label"
                label="Status"
                name='status'
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                <MenuItem value="1">Aktif</MenuItem>
                <MenuItem value="0">Tidak Aktif</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant='outlined' className='mb-3'>
              <InputLabel id="role_label">Role</InputLabel>
              <Select
                labelId="role_label"
                label="Role"
                name='role'
                value={role}
                onChange={e => setRole(e.target.value)}
                required
              >
                {roleList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel component="legend">Kurir</FormLabel>
              <FormGroup>
                {kurirList.map(item => (
                  <FormControlLabel
                    key={item.code} 
                    control={
                      <Checkbox 
                        checked={kurir[item.code]} 
                        onChange={e => kurirHandler(e.target.name, kurir[item.code] ? true : false)} 
                        name={item.code} 
                      />
                    }
                    label={item.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <div className="d-flex flex-row justify-content-end mt-3">
                <Button variant='contained' className='bg-primary text-white align-items-center' onClick={handleSubmit}>
                  <Save /> Simpan
                </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {/* </form> */}
    </>
  )
}

export default React.memo(Tambah)

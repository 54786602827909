import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls, { set } from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Checkbox, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { AccountCircleOutlined, Add, AllInbox, ArrowDownward, ArrowUpward, CategoryOutlined, CheckBoxOutlineBlank, Close, Delete, FastfoodOutlined, ImageOutlined, Inbox, LocationOn, LockOutlined, MailOutline, Map, Save, Search, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const Tambah = (props) => {
  const urlParams = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('asd');
  const [alertType, setAlertType] = useState('error');

  const [categoriList, setCategoriList] = useState([]);

  const [image, setImage] = useState();
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    getAllData();
  }, []);

  async function getCategori() {
    await axios({
      method: "get",
      url: "/api/category/egrocery/",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('Categori:', response.data);
        setCategoriList(response.data.values.data);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  function getAllData() {
    setIsLoading(true);
    Promise.all([getCategori()]).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    })
  }

  function handleSubmit() {
    // e.preventDefault();
    if (
      name === "" ||
      image === undefined || 
      category === "" ||
      status === ""
    ) {
      setAlertType('error');
      setAlertMessage('Lengkapi data');
      setShowAlert(true);
      return false;
    }

    setIsLoading(true);
    let data = new FormData();
    data.append("image", image);
    data.append("name", name);
    data.append("active", status);
    data.append("parent_id", category);

    axios({
      method: "post",
      url: "/api/category/egrocery/",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
      data
    })
      .then((response) => {
        //console.log(response.data);
        if (response.data.status === 200) {
          history.push({
            pathname: '/egrocery/kategori',
            state: {snackbar_message: 'Data Berhasil Ditambakan', snackbar_type: 'success'}
          });
        } else {
          setAlertType('error');
          setAlertMessage(response.data.values.message);
          setShowAlert(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        //console.log(error.response);
        setAlertMessage(error?.response?.data?.values?.message ? error?.response?.data?.values?.message : 'Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      {/* <form onSubmit={handleSubmit}> */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={10} xl={8}>
          <h3>Tambah Kategori E-Grocery</h3>
        </Grid>
        <div className="d-none d-xl-flex w-100" />
        <Grid item xs={12} sm={6} md={5} xl={4}>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
            <h4 className='mb-3'>Gambar</h4>
            <div className='d-flex flex-column align-items-stretch'>
              {image && (
                <img src={image ? URL.createObjectURL(image) : ''} style={{maxHeight: 300, maxWidth: '80%'}} className="mb-3 mx-3 align-self-center" alt="gambar"/>
              )}
              <TextField
                type="file" 
                name="image" 
                variant="outlined" 
                label="Gambar" 
                className="mb-3"
                // value={image}
                onChange={(e) => setImage(e.target.files[0])}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ImageOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Paper>

          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
            <h4 className='mb-3'>Kategori</h4>
            <FormControl variant='outlined' className='mb-3'>
              <InputLabel id="categori_label">Kelompok</InputLabel>
              <Select
                labelId="categori_label"
                label="Kelompok"
                value={category}
                onChange={e => setCategory(e.target.value)}
              >
                {categoriList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              type="text" 
              name="name" 
              variant="outlined" 
              label="Nama" 
              className="mb-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CategoryOutlined color='disabled' />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl variant='outlined' className='mb-3'>
              <InputLabel id="status_label">Status</InputLabel>
              <Select
                labelId="status_label"
                label="Status"
                name="status"
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                <MenuItem value="1">Aktif</MenuItem>
                <MenuItem value="0">Tidak Aktif</MenuItem>
              </Select>
            </FormControl>

            <div className="d-flex flex-row justify-content-end mt-3">
                <Button 
                  variant='contained'
                  className='bg-primary text-white align-items-center' 
                  onClick={handleSubmit}
                >
                  <Save /> Simpan
                </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {/* </form> */}
    </>
  )
}

export default React.memo(Tambah)

import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Fab, FormControl, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { Add, Close, Delete, Search, Visibility } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';
import DialogBoolean from '../../components/dialog/DialogBoolean'


const Kategori = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('error');

  const [showDialog, setShowDialog] = useState(false);
  const [selectedId, setSelectedId] = useState();
  useEffect(() => {
    //console.log(selectedId);
  }, [selectedId]);

  const [search, setSearch] = useState('');

  const [totalPagination, setTotalPagination] = useState(1);
  const [paginationOnPage, setPaginationOnPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const [data, setData] = useState([]);
  var columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        width: '5em',
        style: {
          fontSize: 14,
        }
    },
    {
        name: 'Gambar',
        selector: row => row.image,
        cell: row => (
          <div>
            <img src={row.image} width={40} height={40} alt=""/>
          </div>
        ),
    },
    {
        name: 'Nama',
        selector: row => row.name,
        grow: 3,
        sortable: true,
        style: {
          fontSize: 14,
        }
    },
    {
        name: 'Status',
        selector: row => row.active,
        sortable: true,
        style: {
          fontSize: 14,
        },
        cell: row => (
          <div className='d-flex flex-column'>
            <span 
              className={`badge badge-pill ${row.active == 1 ? 'badge-success' : 'badge-danger'}`}>
              {row.active == 1 ? 'Aktif' : 'Tidak Aktif'}
            </span>
          </div>
        ),
    },
    {
        name: 'Action',
        grow: 2,
        right: true,
        cell: row => (
          <div className='d-flex justify-content-end'>
            <Button 
              variant='outlined' 
              size='small' 
              color='default' 
              onClick={() => history.push({
                pathname: `/ecommerce/kategori/detail/${row.id}`,
                state: {
                  active: row.active,
                  id: row.id,
                  image: row.image,
                  name: row.name,
                },
              })}
            >
              <small><Visibility fontSize='small' /> Details</small>
            </Button>
            <div style={{width: '0.5em', height: '0.5em'}} />
            <Button 
              variant='outlined' 
              size='small' 
              color='secondary' 
              onClick={() => {
                setShowDialog(true);
                setSelectedId(row.id);
              }}>
              <small><Delete fontSize='small' /> Hapus</small>
            </Button>
          </div>
        ),
    },
  ];

  const getData = async (param_text, param_page) => {
    setIsLoading(true);
    axios
      .get(
        `/api/category?search=${param_text}&page=${param_page}`,
        {
          headers: {
            "x-access-token": ls.get("token"),
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        //console.log(response);
        setData(response.data.values.data);
        setTotalPagination(response.data.values.pagination.totalpagination);
        setTotalData(response.data.values.pagination.totaldata);
        setIsLoading(false);
        // setPaginationOnPage(response.data.values.pagination.page);
        setPaginationOnPage(param_page);

        // history.push({
        //   pathname: `/users?page=${param_page}&search=${param_text}&status=${paramstatus}`
        // });
      })
      .catch(error => {
        //console.log(error);
        setIsLoading(false);
      });
  }

  function resetState() {
    setSearch('');
    setPaginationOnPage(1);
  }

  useEffect(() => {
    resetState();
    getData('', 1);
    if (location?.state?.snackbar_message) {
      setAlertMessage(location?.state?.snackbar_message);
      setAlertType(location?.state?.snackbar_type);
      setShowAlert(true);
      window.history.replaceState({}, document.title);
    }
  }, [])
  
  function removeSearch() {
    setSearch('');
  }

  function searchHandler() {
    getData(search, paginationOnPage);
  }

  function nextPage(page) {
    getData(search, page)
  }

  async function handleDelete() {
    setIsLoading(true);
    await axios({
      method: "delete",
      url: `/api/category/${selectedId}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
    })
      .then(response => {
        setIsLoading(false);
        setAlertMessage('Data berhasil dihapus');
        setAlertType('success');
        setShowAlert(true);
        resetState();
        getData('', 1);
        //console.log(response);
      })
      .catch(error => {
        //console.log(error.response);
        setAlertMessage(error?.response?.data?.values?.message ? error?.response?.data?.values?.message : 'Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
        setIsLoading(false);
      });
  }

  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <DialogBoolean 
        open={showDialog} 
        handleClose={() => setShowDialog(false)} 
        title="Peringatan!" 
        content="Apakah anda yakin akan menghapus?"
        onClickYes={handleDelete}
      />

      <Fab color="primary" aria-label="add" variant='extended' style={{position: 'fixed', right: '3em', bottom: '3em', zIndex: 9999, backgroundColor: blue[500]}} className="d-block d-sm-none" onClick={() => history.push('/ecommerce/kategori/tambah')}>
        <Add /> Tambah
      </Fab>

      <Grid container>
        <Grid item xs={12} className="mb-3 d-flex flex-row">
          <h3><span className="text-muted">E-Commerce / </span>Kategori</h3>
          <Button 
            variant="contained" 
            size='small'
            className='ml-auto d-none d-sm-block' 
            style={{backgroundColor: blue[500], color: 'white'}}
            disableElevation={true}
            onClick={() => history.push('/ecommerce/kategori/tambah')}
          >
            <Add /> Tambah
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className="drop-shadow p-3 mb-4">
            <div className='d-flex flex-column flex-sm-row justify-content-sm-end justify-content-start'>
              <TextField 
                type="search" 
                id="outlined-basic" 
                label="Search" 
                variant="outlined"
                size="small"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button 
                        size='small' 
                        variant='text' 
                        style={{minWidth: 0, opacity: search !== '' ? 1 : 0}}
                        disabled={search !== '' ? false : true} 
                        className="text-muted rounded-circle" 
                        onClick={removeSearch}
                      >
                        <Close fontSize='small' />
                      </Button>
                      <Box bgcolor={blue[500]} className='text-white p-2 search-button' onClick={searchHandler}>
                        <Search color='inherit' />
                      </Box>
                    </InputAdornment>
                  ),
                  style: {paddingRight: 0, overflow: 'hidden'},
                  color: blue[500]
                }}
              />
            </div>
              <DataTable
                columns={columns}
                data={data}
                // selectableRows
                pagination
                paginationServer
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  rangeSeparatorText: 'baris',
                  selectAllRowsItem: false,
                }}
                onChangePage={page => nextPage(page)}
                paginationTotalRows={totalData}
                paginationPerPage={20}
              />
            <div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(Kategori)

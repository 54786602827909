import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useHistory, withRouter } from "react-router-dom";
// import { Breakpoint } from 'react-socks';
import iconMenu from './icon-menu.svg';
import './navbar.scss';
import ls from 'local-storage';
import { jwtDecode } from '../../../helpers/helpers';

const Navbar = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const userData = jwtDecode(ls.get('token'));

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSetting() {
    history.push('/profil')
  }

  const handleLogout = () => {
    ls.remove('isLogin');
    ls.remove('token');
    ls.remove('userData');
    ls.remove('menu');
    props.history.push('/login');
    setAnchorEl(null);
  }

  return (
    <>
      <div className={`py-2 px-md-0 px-lg-3 bg-white fixed-top mynavbar`}>
        <div className="container-fluid">
          <div className="align-items-center row">
            <div className="d-inline-block d-lg-none mr-3 ml-4">
              <img src={iconMenu} onClick={props.handleShowMenuMobile} alt="..." />
            </div>
            <div className="col-lg-4 col">
              <span className="h5 font-bold">
                <div className="d-none d-lg-block" style={{ letterSpacing: '-0.7px', marginTop: '-3px' }}>
                  MPSTORE MARKETPLACE
                </div>
                <div className="d-block d-lg-none" style={{ marginTop: '-2px' }}>
                  MARKETPLACE
                </div>
              </span>
            </div>
            <div className="col-lg-8 col">
              <div className="d-flex justify-content-end align-items-center">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle /> <span style={{fontSize: 16}}>{userData.name}</span>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleSetting}>Profil</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Navbar)

import React, { useState, useEffect, createRef, useRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Checkbox, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField, ThemeProvider } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { AccessTime, AccountCircleOutlined, Add, Call, Cancel, CancelOutlined, CheckCircle, CheckCircleOutline, Close, Comment, Delete, Description, DirectionsRun, Edit, LocalShipping, LocationCity, LocationOn, LockOutlined, MailOutline, Map, MarkunreadMailboxOutlined, MoreHoriz, PinDrop, RotateLeft, Save, Search, ThumbUpOutlined, Today, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons'
import { blue, red } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/styles'
import DialogBoolean from '../../components/dialog/DialogBoolean'

const MyTextInput = styled(TextField)(() => ({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#555",
    color: "#555"
  }
}))

const MyFormControl = styled(FormControl)(() => ({
  "& .MuiInputBase-root.Mui-disabled": {
    WebkitTextFillColor: "#555",
    color: "#555"
  }
}))

const Detail = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const userData = ls.get("userData");

  const nameRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('asd');
  const [alertType, setAlertType] = useState('error');

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogReset, setShowDialogReset] = useState(false);
  const [noResiBaru, setNoResiBaru] = useState('');

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [roleList, setRoleList] = useState([]);
  const [provinsiList, setProvinsiList] = useState([]);
  const [kotaList, setKotaList] = useState([]);
  const [kurirList, setKurirList] = useState([]);

  const [id, setId] = useState('');
  const [row, setRow] = useState();
  const [detail, setDetail] = useState();
  const [pengiriman, setPengiriman] = useState();

  const columns = [
    {
      name: 'Produk',
      selector: row => row.name,
      sortable: true,
      grow: 2,
      wrap: true
    },
    {
      name: 'Kuantitas',
      selector: row => row.qty,
      sortable: true,
      right: true,
    },
    {
      name: 'Harga',
      selector: row => row.price,
      sortable: true,
      right: true,
    },
    {
      name: 'Total',
      selector: row => row.amount,
      sortable: true,
      right: true,
    },
  ];

  useEffect(() => {
    if (!isReadOnly) {
      nameRef.current.focus();
    }
  }, [isReadOnly])
  
  async function getDetail() {
    setIsLoading(true);
    await axios({
      method: "get",
      url: `/api/transactions/get-single/${location?.state?.row?.id}?pengiriman=${location?.state?.row?.pengiriman}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('Detail:', response.data);
        setDetail(response.data.values.detail[0]);
        setPengiriman(response.data.values.pengiriman[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setIsLoading(false);
      });
  }

  async function handleEditNoResi(val) {
    setShowDialogReset(false);
    setIsLoading(true);
    //console.log(val);
    await axios({
      method: "put",
      url: "/api/transactions/update-noresi/"+id,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
      data: {
        no_resi: val,
        idseller_purchaser: row.idseller_purchaser,
      }
    })
      .then(response => {
        //console.log(response);
        setIsLoading(false);
        setAlertMessage('No Resi Berhasil Diubah');
        setAlertType('success');
        setShowAlert(true);
        setDetail({
          ...detail,
          no_resi: val,
        });
      })
      .catch(error => {
        //console.log(error);
        setAlertMessage('Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
      });
  }

  async function handleChangeStatus(val) {
    setShowDialogReset(false);
    setIsLoading(true);
    //console.log(val);
    await axios({
      method: "put",
      url: "/api/transactions/update-status/"+id,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
      data: {
        status: val,
        idseller_purchaser: row.idseller_purchaser,
      }
    })
      .then(response => {
        //console.log(response);
        setIsLoading(false);
        setAlertMessage('Update Berhasil');
        setAlertType('success');
        setShowAlert(true);
        setDetail({
          ...detail,
          status: val,
        });
      })
      .catch(error => {
        //console.log(error);
        setAlertMessage('Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
      });
  }

  useEffect(() => {
    getPassingData();
    getDetail();
  }, []);

  function getPassingData() {
    setId(location?.state?.row?.id);
    setRow(location?.state?.row);
    //console.log(location?.state?.row);
  }

  function resetData() {
    setIsReadOnly(true);
    getPassingData();
    getDetail()
  }
  
  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={showDialogReset}
        onClose={() => setShowDialogReset(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{paddingBottom: 0}}>Edit No Resi</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            type="text"
            placeholder="No Resi"
            value={noResiBaru}
            onChange={e => setNoResiBaru(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowDialogReset(false)} color="primary">
              Kembali
            </Button>
            <Button onClick={() => handleEditNoResi(noResiBaru)} color="primary" autoFocus>
              Simpan
            </Button>
        </DialogActions>
      </Dialog>

      {/* <form onSubmit={handleSubmit}> */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={10} xl={8} className="d-flex flex-column flex-sm-row">
            <h3>Detail</h3>
            {userData.role_id != 1 && (
              <div className='ml-auto d-flex'>
                {detail?.status == '1' ? (
                  <>
                    <div style={{width: '0.5em', height: '0.5em'}} />
                    <Button 
                      variant='contained' 
                      className='text-white bg-primary'
                      onClick={() => handleChangeStatus(2)}
                    >
                      <small><CheckCircle fontSize='small' /> Approve</small>
                    </Button>
                  </>
                ) : detail?.status == '2' ? (
                  <>
                    <div style={{width: '0.5em', height: '0.5em'}} />
                    <Button 
                      variant='contained' 
                      className='text-white bg-success'
                      onClick={() => handleChangeStatus(3)}
                    >
                      <small><CheckCircle fontSize='small' /> Selesai</small>
                    </Button>
                  </>
                ) : null}
                {detail?.status != '3' && detail?.status != '4' && (
                  <>
                    <div style={{width: '0.5em', height: '0.5em'}} />
                    <Button 
                      variant='contained' 
                      className='text-white bg-danger ml-2'
                      onClick={() => handleChangeStatus(4)}
                    >
                      <small><Cancel fontSize='small' /> Batalkan</small>
                    </Button>
                  </>
                )}
                <Button variant='contained' size='small' className='bg-dark text-white align-items-center ml-2' onClick={() => setShowDialogReset(true)}>
                  <Edit /> Edit No Resi
                </Button>
              </div>
            )}
          </Grid>
          <div className="d-none d-xl-flex w-100" />
          <Grid item xs={12} sm={6} md={5} xl={4}>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
              <h4 className='mb-3'>Penjual</h4>
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="ID Reseller Penjual" 
                className="mb-3"
                value={userData?.idreseller}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Alamat Penjual" 
                className="mb-3"
                value={detail?.address_penjual}
                multiline={true}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
              <h4 className='mb-3'>Informasi Customer</h4>
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Nama" 
                className="mb-3"
                value={pengiriman?.name}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Nomor Telepon" 
                className="mb-3"
                value={pengiriman?.phone}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Call color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Jenis tempat" 
                className="mb-3"
                value={pengiriman?.title}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PinDrop color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Alamat" 
                className="mb-3"
                value={pengiriman?.address}
                multiline={true}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Provinsi" 
                className="mb-3"
                value={pengiriman?.province_name}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Map color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Kota" 
                className="mb-3"
                value={pengiriman?.city_name}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCity color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Kecamatan" 
                className="mb-3"
                value={pengiriman?.district_name}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="number" 
                variant="outlined" 
                label="Kode POS" 
                className="mb-3"
                value={pengiriman?.zipcode}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MarkunreadMailboxOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={5} xl={4}>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
              <h4 className='mb-3'>Transaksi</h4>
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Status" 
                className="mb-3"
                value={detail?.status == 1 ? 'Pending' : detail?.status == 2 ? 'Approved' : detail?.status == 3 ? 'Pesanan Selesai' : detail?.status == 4 ? 'Pesanan Dibatalkan' : ''}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MoreHoriz color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Tanggal Transaksi" 
                className="mb-3"
                value={moment(row?.created_at).format("DD MMM YYYY HH:mm")+" WIB"}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Today color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>

            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
              <h4 className='mb-3'>Pengiriman</h4>
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Pengiriman" 
                className="mb-3"
                value={detail?.courier}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalShipping color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Service" 
                className="mb-3"
                value={detail?.service}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DirectionsRun color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="No. Resi" 
                className="mb-3"
                value={detail?.no_resi}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyTextInput
                type="text" 
                variant="outlined" 
                label="Catatan Pembeli" 
                className="mb-3"
                value={detail?.catatan_pembeli}
                disabled={isReadOnly}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Comment color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>

            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
              <h4 className='mb-3'>Order Summary</h4>
              <DataTable
                columns={columns}
                data={detail?.data_order}
                responsive={true}
              />
              <table>
                <tr>
                  <td className="py-2 border-bottom border-top pr-3 text-right text-black" style={{fontSize: 13}}>
                    Sub Total: 
                  </td>
                  <td className="py-2 border-bottom border-top pr-3 text-right text-black" style={{fontSize: 13, width: '7em'}}>
                    Rp{getRupiah(detail?.subtotal_barang ? detail?.subtotal_barang : 0)}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 border-bottom border-top pr-3 text-right text-black" style={{fontSize: 13}}>
                    Ongkos Kirim:
                    <div>
                      ({detail?.courier.toUpperCase()} : {detail?.service})
                    </div>
                  </td>
                  <td className="py-2 border-bottom border-top pr-3 text-right text-black" style={{fontSize: 13, width: '7em'}}>
                    Rp{getRupiah(detail?.total_ongkir_barang ? detail?.total_ongkir_barang : 0)}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 border-bottom border-top pr-3 text-right text-black font-weight-bold" style={{fontSize: 14}}>
                    Total:
                  </td>
                  <td className="py-2 border-bottom border-top pr-3 text-right text-black font-weight-bold" style={{fontSize: 14, width: '7em'}}>
                    Rp{getRupiah(detail?.subtotal_barang + detail?.total_ongkir_barang)}
                  </td>
                </tr>
              </table>
            </Paper>
          </Grid>
        </Grid>
      {/* </form> */}
    </>
  )
}

export default React.memo(Detail)

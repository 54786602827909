import React, { useState, useEffect, useRef } from 'react'
import Loader from '../../components/loader/Loader'
import ls from 'local-storage'
import axios from "axios";
import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, TextField } from '@material-ui/core'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AllInbox, ArrowDownward, ArrowUpward, Delete, Edit, FastfoodOutlined, ImageOutlined, Inbox, RotateLeft, Save } from '@material-ui/icons'
import MuiAlert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/styles'
import DialogBoolean from '../../components/dialog/DialogBoolean'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const MyTextInput = styled(TextField)(() => ({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#555",
    color: "#555"
  }
}))

const MyFormControl = styled(FormControl)(() => ({
  "& .MuiInputBase-root.Mui-disabled": {
    WebkitTextFillColor: "#555",
    color: "#555"
  }
}))

const Detail = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const nameRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('asd');
  const [alertType, setAlertType] = useState('error');

  const [showDialogDelete, setShowDialogDelete] = useState(false);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [categoriList, setCategoriList] = useState([]);

  const [id, setId] = useState();
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (!isReadOnly) {
      nameRef.current.focus();
    }
  }, [isReadOnly])
  

  useEffect(() => {
    getPassingData();
  }, []);

  function getPassingData() {
    setId(location?.state?.id);
    setName(location?.state?.name);
    setImage(location?.state?.imageP);
    setImagePreview(location?.state?.image);
    setStatus(location?.state?.active);

    //console.log(location?.state);
  }

  function resetData() {
    setIsReadOnly(true);
    setImage();
    getPassingData();
  }

  function handleSubmit() {
    // e.preventDefault();
    if (
      name === ""
    ) {
      setAlertType('error');
      setAlertMessage('Lengkapi data');
      setShowAlert(true);
      return false;
    }
    
    setIsLoading(true);
    let data = new FormData();
    if (image) {
      data.append("image", image);
    }
    data.append("name", name);
    data.append("active", status);

    axios({
      method: "put",
      url: `/api/category/${id}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
      data
    })
      .then((response) => {
        //console.log(response.data);
        if (response.data.status === 200) {
          setAlertType('success');
          setAlertMessage('Perubahan berhasil disimpan');
          setShowAlert(true);
          setIsReadOnly(true);
          
          let updateState = {
            active: status,
            id: id,
            name: name,
          };
          if (image) {
            updateState.imageP = image;
          } else {
            updateState.image = location?.state?.image;
          }
          history.replace(location.pathname, updateState);
        } else {
          setAlertType('error');
          setAlertMessage(response.data.values.message);
          setShowAlert(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        //console.log(error.response);
        setAlertMessage(error?.response?.data?.values?.message ? error?.response?.data?.values?.message : 'Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
        setIsLoading(false);
      });
  };

  async function handleDelete() {
    setIsLoading(true);
    await axios({
      method: "delete",
      url: `/api/category/${id}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
    })
      .then(response => {
        setIsLoading(false);
        history.push({
          pathname: '/ecommerce/kategori',
          state: {snackbar_message: 'Data berhasil dihapus', snackbar_type: 'success'}
        });
      })
      .catch(error => {
        //console.log(error.response);
        setAlertMessage(error?.response?.data?.values?.message ? error?.response?.data?.values?.message : 'Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
      });
  }
  
  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <DialogBoolean 
        open={showDialogDelete} 
        handleClose={() => setShowDialogDelete(false)} 
        title="Peringatan!" 
        content="Apakah anda yakin akan menghapus?"
        onClickYes={handleDelete}
      />

      {/* <form onSubmit={handleSubmit}> */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={10} xl={8} className="d-flex flex-column flex-sm-row">
            <h3>Detail</h3>
            <div className='ml-auto d-flex'>
              {isReadOnly && (
                <Button variant='contained' size='small' className='bg-primary text-white align-items-center' onClick={() => setIsReadOnly(false)}>
                  <Edit /> Edit
                </Button>
              )}
              <Button variant='contained' size='small' className='bg-danger text-white align-items-center ml-2' onClick={() => setShowDialogDelete(true)}>
                <Delete /> Hapus
              </Button>
            </div>
          </Grid>
          <div className="d-none d-xl-flex w-100" />
          <Grid item xs={12} sm={6} md={5} xl={4}>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
            <h4 className='mb-3'>Gambar</h4>
            <div className='d-flex flex-column align-items-stretch'>
              <img src={image ? URL.createObjectURL(image) : imagePreview ? imagePreview : ''} style={{maxHeight: 300, maxWidth: '80%'}} className="mb-3 mx-3 align-self-center" alt="gambar"/>
              {!isReadOnly && (
                <MyTextInput
                  type="file" 
                  name="image" 
                  variant="outlined" 
                  label="Gambar" 
                  className="mb-3"
                  disabled={isReadOnly}
                  onChange={(e) => setImage(e.target.files[0])}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ImageOutlined color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </div>
            </Paper>

            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
              <h4 className='mb-3'>Kategori</h4>
              <MyTextInput
                inputRef={nameRef}
                type="text" 
                name="name" 
                variant="outlined" 
                label="Nama" 
                className="mb-3"
                value={name}
                disabled={isReadOnly}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FastfoodOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly}>
                <InputLabel id="status_label">Status</InputLabel>
                <Select
                  labelId="status_label"
                  label="Status"
                  name="status"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <MenuItem value="1">Aktif</MenuItem>
                  <MenuItem value="0">Tidak Aktif</MenuItem>
                </Select>
              </MyFormControl>

                <div className="d-flex flex-row justify-content-end mt-3">
                  {!isReadOnly && (
                    <>
                      <Button variant='contained' className='bg-secondary text-white align-items-center mr-1' onClick={() => resetData()}>
                        <RotateLeft /> Cancel
                      </Button>
                      <Button variant='contained' className='bg-success text-white align-items-center' onClick={handleSubmit}>
                        <Save /> Simpan Perubahan
                      </Button>
                    </>
                  )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      {/* </form> */}
    </>
  )
}

export default React.memo(Detail)

import React, { useState, useEffect, createRef, useRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Checkbox, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField, ThemeProvider } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { AccountCircleOutlined, Add, Close, Delete, Edit, LocationOn, LockOutlined, MailOutline, Map, RotateLeft, Save, Search, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons'
import { blue, red } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/styles'
import DialogBoolean from '../../components/dialog/DialogBoolean'
import { emailV, joinV, minLengthV, nameV, notNullV } from '../../helpers/validation'

const MyTextInput = styled(TextField)(() => ({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#555",
    color: "#555"
  }
}))

const MyFormControl = styled(FormControl)(() => ({
  "& .MuiInputBase-root.Mui-disabled": {
    WebkitTextFillColor: "#555",
    color: "#555"
  }
}))

const Detail = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const nameRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('asd');
  const [alertType, setAlertType] = useState('error');

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogReset, setShowDialogReset] = useState(false);
  const [passwordBaru, setPasswordBaru] = useState('');

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [roleList, setRoleList] = useState([]);
  const [provinsiList, setProvinsiList] = useState([]);
  const [kotaList, setKotaList] = useState([]);
  const [kurirList, setKurirList] = useState([]);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passwordVisible, setPasswordVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [provinsi, setProvinsi] = useState('');
  const [kota, setKota] = useState('');
  const [alamat, setAlamat] = useState('');
  const [kurir, setKurir] = useState({});
  const [idReseller, setIdReseller] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [validation, setValidation] = useState({
    name: {},
    role: {},
    email: {},
    status: {},
    provinsi: {},
    kota: {},
    alamat: {},
  });

  function kurirHandler(code, value) {
    let temp = {...kurir};
    temp[code] = !value;
    // //console.log(temp);
    setKurir(temp);
  }

  useEffect(() => {
    if (!isReadOnly) {
      nameRef.current.focus();
    }
  }, [isReadOnly])
  

  useEffect(() => {
    getPassingData();
    getAllData();
  }, []);

  function getPassingData() {
    setId(location?.state?.id);
    setName(location?.state?.name);
    setRole(location?.state?.role_id);
    setEmail(location?.state?.email);
    setStatus(location?.state?.active);
    setProvinsi(location?.state?.province);
    setKota(location?.state?.city);
    setAlamat(location?.state?.address);
    setIdReseller(location?.state?.idreseller);
    setLatitude(location?.state?.latitude);
    setLongitude(location?.state?.longitude);

    let kurirTemp = location?.state?.courier.split(',');
    let newKurir = {}
    kurirTemp.forEach(item => {
      newKurir[item] = true;
    });
    setKurir(newKurir);

    //console.log(location?.state);
  }

  function getAllData() {
    setIsLoading(true);
    Promise.all([getRole(), getProvince(), getCourier(), getCity(location?.state?.province)]).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    })
  }

  function resetData() {
    setIsReadOnly(true);
    getPassingData();
    getAllData();
  }

  async function getRole() {
    await axios({
      method: "get",
      url: "/api/admin/get-role",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('role:', response.data);
        setRoleList(response.data.values);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  async function getCity(id) {
    await axios({
      method: "get",
      url: `/api/admin/get-city?&province=${id}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('kota:', response.data);
        setKotaList(response.data.values.data);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  async function getProvince() {
    await axios({
      method: "get",
      url: "/api/admin/get-province",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
    })
      .then(response => {
        //console.log('Provinsi:', response.data);
        setProvinsiList(response.data.values.data);
      })
      
      .catch(error => {
        //console.log(error);
      });
  }

  async function getCourier() {
    await axios({
      method: "get",
      url: "/api/courier/all",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('Kurir:', response.data);
        setKurirList(response.data.values);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  function handleSubmit() {
    // e.preventDefault();
    // if (
    //   name === "" ||
    //   email === "" ||
    //   // password === "" ||
    //   kurir.length == 0 ||
    //   provinsi === "" ||
    //   kota === "" ||
    //   alamat === ""
    // ) {
    //   setAlertType('error');
    //   setAlertMessage('Lengkapi data');
    //   setShowAlert(true);
    //   return false;
    // }

    let vt = {
      name: joinV([nameV(name), minLengthV(name, 2)]),
      role: notNullV(role),
      email: emailV(email),
      status: notNullV(status),
      provinsi: notNullV(provinsi),
      kota: notNullV(kota),
      alamat: joinV([notNullV(alamat), minLengthV(alamat, 2)]),
    }
    setValidation(vt);
    if (vt.name.error || vt.role.error || vt.email.error || vt.status.error || vt.provinsi.error || vt.kota.error || vt.alamat.error) {
      return false;
    }

    setIsLoading(true);
    let courier = [];
    let final_cour = "";
    kurirList.forEach(item => {
      if (kurir[item.code]) {
        courier.push(item.code);
      }
    });
    final_cour = courier.join();

    axios({
      method: "put",
      url: `/api/admin/update-data/${id}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
      data: {
        id: id,
        name: name,
        role_id: role,
        email: email,
        active: status,
        city: kota,
        province: provinsi,
        postal_code: 0,
        courier: final_cour,
        address: alamat,
        idreseller: idReseller,
        longitude: longitude,
        latitude: latitude,
      },
    })
      .then((response) => {
        //console.log(response);
        setAlertMessage('Perubahan berhasil disimpan');
        setAlertType('success');
        setShowAlert(true);
        setIsReadOnly(true);
        setIsLoading(false);
          
        let updateState = {
          id: id,
          name: name,
          email: email,
          idreseller: idReseller,
          active: status,
          address: alamat,
          courier: final_cour,
          city: kota,
          province: provinsi,
          postal_code: 0,
          latitude: latitude,
          longitude: longitude,
          role_id: role,
        };
        history.replace(location.pathname, updateState);
      })
      .catch((error) => {
        //console.log(error?.response);
        setAlertType('error');
        setAlertMessage(error?.response?.data?.values?.message ? error?.response?.data?.values?.message : 'Terjadi Kesalahan');
        setShowAlert(true);
        resetData();
        setIsLoading(false);
      });
  };

  async function handleDelete() {
    setIsLoading(true);
    await axios({
      method: "delete",
      url: `/api/admin/${id}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
    })
      .then(response => {
        setIsLoading(false);
        history.push({
          pathname: '/users',
          state: {snackbar_message: 'Data berhasil dihapus', snackbar_type: 'success'}
        });
      })
      .catch(error => {
        //console.log(error);
        setAlertMessage('Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
        setIsLoading(false);
      });
  }

  async function handleResetPassword(val) {
    setShowDialogReset(false);
    setIsLoading(true);
    //console.log(val);
    await axios({
      method: "put",
      url: "/api/admin/reset-password/"+id,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
      data: {
        password: val,
      }
    })
      .then(response => {
        setIsLoading(false);
        setAlertMessage('Reset Password Berhasil');
        setAlertType('success');
        setShowAlert(true);
      })
      .catch(error => {
        //console.log(error);
        setAlertMessage('Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
      });
  }
  
  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <DialogBoolean 
        open={showDialogDelete} 
        handleClose={() => setShowDialogDelete(false)} 
        title="Peringatan!" 
        content="Apakah anda yakin akan menghapus user ini?"
        onClickYes={handleDelete}
      />

      <Dialog
        open={showDialogReset}
        onClose={() => setShowDialogReset(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{paddingBottom: 0}}>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            type="password"
            placeholder="Password Baru"
            value={passwordBaru}
            onChange={e => setPasswordBaru(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowDialogReset(false)} color="primary">
              Kembali
            </Button>
            <Button onClick={() => handleResetPassword(passwordBaru)} color="primary" autoFocus>
              Simpan
            </Button>
        </DialogActions>
      </Dialog>

      {/* <form onSubmit={handleSubmit}> */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={10} xl={8} className="d-flex flex-column flex-sm-row">
            <h3>Detail</h3>
            <div className='ml-auto d-flex'>
              {isReadOnly && (
                <Button variant='contained' size='small' className='bg-primary text-white align-items-center' onClick={() => setIsReadOnly(false)}>
                  <Edit /> Edit
                </Button>
              )}
              <Button variant='contained' size='small' className='bg-warning text-white align-items-center ml-2' onClick={() => setShowDialogReset(true)}>
                <VpnKey /> Reset Password
              </Button>
              {/* <Button variant='contained' size='small' className='bg-danger text-white align-items-center ml-2' onClick={() => setShowDialogDelete(true)}>
                <Delete /> Hapus
              </Button> */}
            </div>
          </Grid>
          <div className="d-none d-xl-flex w-100" />
          <Grid item xs={12} sm={6} md={5} xl={4}>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
              <h4 className='mb-3'>Data Diri</h4>
              <MyTextInput
                  type="text" 
                  name="name" 
                  variant="outlined" 
                  label="Nama" 
                  className="mb-3"
                  value={name}
                  inputRef={nameRef}
                  onChange={(e) => setName(e.target.value)}
                  error={validation?.name?.error}
                  helperText={validation?.name?.message}
                  disabled={isReadOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlined color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
              <MyTextInput
                  type="email" 
                  name="email" 
                  variant="outlined" 
                  label="Email" 
                  className="mb-3"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={validation?.email?.error}
                  helperText={validation?.email?.message}
                  disabled={isReadOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutline color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
              {/* <MyTextInput 
                  type={passwordVisible ? 'text' : 'password'} 
                  name="password" 
                  variant="outlined" 
                  label="Password"
                  className="mb-3"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isReadOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined color='disabled' />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start" onClick={() => setPasswordVisible(!passwordVisible)} style={{cursor: 'pointer'}}>
                        {passwordVisible ? (
                          <VisibilityOff color='disabled' />
                        ) : (
                          <Visibility color='disabled' />
                        )}
                      </InputAdornment>
                    )
                  }}
                /> */}
            </Paper>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
              <h4 className='mb-3'>Alamat</h4>
              <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly} error={validation?.provinsi?.error}>
                <InputLabel id="provinsi_label">Provinsi</InputLabel>
                <Select
                  labelId="provinsi_label"
                  label="Provinsi"
                  name="provinsi"
                  // disabled={isReadOnly}
                  value={provinsi}
                  onChange={e => {setProvinsi(e.target.value); setKota(''); getCity(e.target.value);}}
                >
                  <MenuItem value="" selected disabled>Pilih Provinsi</MenuItem>
                  {provinsiList.map((item, index) => (
                    <MenuItem key={index} value={item.province_id}>{item.province}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{validation?.provinsi?.message}</FormHelperText>
              </MyFormControl>
              <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly} error={validation?.kota?.error}>
                <InputLabel id="kota_label">Kota</InputLabel>
                <Select
                  labelId="kota_label"
                  label="Kota"
                  name="kota"
                  placeholder='Pilih Kota/Kabupaten'
                  value={kota}
                  onChange={e => setKota(e.target.value)}
                >
                  <MenuItem value="" selected disabled>Pilih Kota</MenuItem>
                  {kotaList.map((item, index) => (
                    <MenuItem key={index} value={item.city_id}>{item.city_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{validation?.kota?.message}</FormHelperText>
              </MyFormControl>
              <MyTextInput
                  type="text" 
                  name="alamat" 
                  variant="outlined" 
                  label="Alamat" 
                  className="mb-3"
                  multiline={true}
                  disabled={isReadOnly}
                  value={alamat}
                  onChange={(e) => setAlamat(e.target.value)}
                  error={validation?.alamat?.error}
                  helperText={validation?.alamat?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
              <MyTextInput
                  type="text" 
                  name="latitude" 
                  variant="outlined" 
                  label="Latitude" 
                  className="mb-3"
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                  error={validation?.latitude?.error}
                  helperText={validation?.latitude?.message}
                  disabled={isReadOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
              <MyTextInput
                  type="text" 
                  name="longitude" 
                  variant="outlined" 
                  label="Longitude" 
                  className="mb-3"
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                  error={validation?.longitude?.error}
                  helperText={validation?.longitude?.message}
                  disabled={isReadOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={5} xl={4}>
            <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
              <h4 className='mb-3'>Akun</h4>
              <MyTextInput
                  type="text" 
                  name="id_reseller" 
                  variant="outlined" 
                  label="ID Reseller" 
                  className="mb-3"
                  value={idReseller}
                  onChange={(e) => setIdReseller(e.target.value)}
                  error={validation?.idReseller?.error}
                  helperText={validation?.idReseller?.message}
                  disabled={isReadOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKey color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
              <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly} error={validation?.status?.error}>
                <InputLabel id="status_label">Status</InputLabel>
                <Select
                  labelId="status_label"
                  label="Status"
                  name='status'
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <MenuItem value="1">Aktif</MenuItem>
                  <MenuItem value="0">Tidak Aktif</MenuItem>
                </Select>
                <FormHelperText>{validation?.status?.message}</FormHelperText>
              </MyFormControl>
              <MyFormControl variant='outlined' className='mb-3' disabled={isReadOnly} error={validation?.role?.error}>
                <InputLabel id="role_label">Role</InputLabel>
                <Select
                  labelId="role_label"
                  label="Role"
                  name='role'
                  value={role}
                  onChange={e => setRole(e.target.value)}
                  required
                >
                  {roleList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{validation?.role?.message}</FormHelperText>
              </MyFormControl>
              <MyFormControl component="fieldset" disabled={isReadOnly}>
                <FormLabel component="legend">Kurir</FormLabel>
                <FormGroup>
                  {kurirList.map(item => (
                    <FormControlLabel
                      key={item.code} 
                      control={
                        <Checkbox 
                          checked={kurir[item.code]} 
                          onChange={e => kurirHandler(e.target.name, kurir[item.code] ? true : false)} 
                          name={item.code} 
                        />
                      }
                      label={item.name}
                    />
                  ))}
                </FormGroup>
              </MyFormControl>
              <div className="d-flex flex-row justify-content-end mt-3">
                  {!isReadOnly && (
                    <>
                      <Button variant='contained' className='bg-secondary text-white align-items-center mr-1' onClick={resetData}>
                        <RotateLeft /> Cancel
                      </Button>
                      <Button variant='contained' className='bg-success text-white align-items-center' onClick={handleSubmit}>
                        <Save /> Simpan Perubahan
                      </Button>
                    </>
                  )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      {/* </form> */}
    </>
  )
}

export default React.memo(Detail)

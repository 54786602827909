import React, { Component } from "react";
import NavSideList from "./NavSideList";
import "./navside.scss";
import ls from "local-storage";
import { jwtDecode } from "../../helpers/helpers";

export default class NavSide extends Component {

  state = {
    menuList: [
      {
        text: "dashboard",
        path: "/",
        children: false,
        textIcon: "home"
      },
    ]
  };

  handleShowChildren = id => {
    const menu = [...this.state.menuList];
    menu.map((item, index) => {
      if (index !== id) {
        return (item.childrenShow = false);
      }
    });
    menu[id].childrenShow = !menu[id].childrenShow;
    this.setState({ menuList: menu });
  };

  getMenu = () => {
    const role_id = jwtDecode(ls.get('token')).role_id;
    const oldmenu = this.state.menuList;
    if (role_id == 1) {
      oldmenu.push(
        {
          text: "E-Commerce",
          path: "/ecommerce",
          children: [
            {text: 'Produk', path: '/ecommerce/produk', textIcon: 'fastfood'},
            {text: 'Kategori', path: '/ecommerce/kategori', textIcon: 'category'}
          ],
          textIcon: "local_mall"
        },
        {
          text: "E-Grocery",
          path: "/egrocery",
          children: [
            {text: 'Produk', path: '/egrocery/produk', textIcon: 'fastfood'},
            {text: 'Kategori', path: '/egrocery/kategori', textIcon: 'category'}
          ],
          textIcon: "storefront"
        },
        {
          text: "Produk Haji/Umroh",
          path: "/travel/produk",
          children: false,
          textIcon: "flight"
        },{
          text: "Cicilan Haji/Umroh",
          path: "/cicilan",
          children: false,
          textIcon: "payments"
        },
        {
          text: "Transaksi",
          path: "/transaksi",
          children: false,
          textIcon: "currency_exchange"
        },
        {
          text: "Users",
          path: "/users",
          children: false,
          textIcon: "people"
        },
        {
          text: "Role",
          path: "/role",
          children: false,
          textIcon: "admin_panel_settings"
        }
      );
    }
    if (role_id == 3) {
      oldmenu.push({
        text: "Produk E-Commerce",
        path: "/ecommerce/produk",
        children: false,
        textIcon: "local_mall"
      });
      oldmenu.push({
        text: "Produk E-Grocery",
        path: "/egrocery/produk",
        children: false,
        textIcon: "storefront"
      });
      oldmenu.push({
        text: "Transaksi",
        path: "/transaksi",
        children: false,
        textIcon: "currency_exchange"
      });
    }
    if (role_id == 4) {
      oldmenu.push({
        text: "Produk Haji/Umroh",
        path: "/travel/produk",
        children: false,
        textIcon: "flight"
      });
      oldmenu.push({
        text: "Cicilan Haji/Umroh",
        path: "/cicilan",
        children: false,
        textIcon: "payments"
      });
    }

    oldmenu.push(
      {
        text: "Profil",
        path: "/profil",
        children: false,
        textIcon: "person"
      },
    );
    this.setState({menuList: oldmenu});
  }

  componentDidMount() {
    this.getMenu();
  }

  render() {
    return (
      <>
        <div className="navside-ew431">
          <ul className="parent">
            {this.state.menuList.map((menu, index) => (
              <NavSideList
                key={index}
                index={index}
                menu={menu}
                handleShowChildren={this.handleShowChildren}
                handleCloseMenuMobile={this.props.handleCloseMenuMobile}
              />
            ))}
          </ul>
        </div>
      </>
    );
  }
}

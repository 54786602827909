import React, {useState} from 'react'
import ls from 'local-storage'
import { Button, InputAdornment, Snackbar, TextField } from '@material-ui/core'
import { AccountCircleOutlined, LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons'
import Loader from '../../components/loader/Loader';
import { MyAlert } from '../../components/modal/Mymodal';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import { jwtDecode } from '../../helpers/helpers';

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('error');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  function loginHandler (e) {
    e.preventDefault();
    if (username === '' || password === '') {
      setAlertMessage('Lengkapi form');
      setAlertType("error");
      setShowAlert(true);
      return false;
    }
    setIsLoading(true);
    fetch('api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: username,
        password: password
      })
    })
    .then((res) => {
      if (!res.ok) {
        // throw Error(res.text());
        //console.log(res);
      }
      return res.json();
    })
    .then((res) => {
      //console.log(res);

      setIsLoading(false);
      // alert(res.values.message);
      if (
        res.values.message === "User or Password is Invalid" ||
        res.values.message === "User is Blocked"
        ) {
          setAlertType("error");
          setShowAlert(true);
          setAlertMessage(res.values.message);
          props.history.push("/login");
          ls.set("isLogin", false);
        } else {
          setAlertType("success");
          setShowAlert(true);
          setAlertMessage(res.values.message);
          ls.set("isLogin", true);
          ls.set("token", res.values.token);
          ls.set("userData", jwtDecode(res.values.token));
          ls.set("menu", res.values.menu);
          history.push({
            pathname: '/',
            state: {snackbar_message: 'Login sukses', snackbar_type: 'success'}
          });
        }
    })
    .catch((err) => {
      setAlertType("error");
      setIsLoading(false);
      setAlertMessage('Login Failed');
      setShowAlert(true);
      props.history.push("/login");
      //console.log(err);
    });
  }
  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />
      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
      <div className='container-fluid h-100vh d-flex justify-content-center align-items-center bg-light'>
        <div className='bg-white p-4 rounded-lg d-flex flex-column align-items-center drop-shadow' style={{width: 350}}>
          <img 
            src={require('../../img/logo-real.png')} 
            style={{width: 200}} 
            className="mb-4"
            alt="logo"
          />
          <form onSubmit={loginHandler} method="POST" style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
              <TextField
                type="text" 
                name="username" 
                variant="outlined" 
                label="Username" 
                className="mb-3"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField 
                type={passwordVisible ? 'text' : 'password'} 
                name="password" 
                variant="outlined" 
                label="Password"
                className="mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined color='disabled' />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start" onClick={() => setPasswordVisible(!passwordVisible)} style={{cursor: 'pointer'}}>
                      {passwordVisible ? (
                        <VisibilityOff color='disabled' />
                      ) : (
                        <Visibility color='disabled' />
                      )}
                    </InputAdornment>
                  )
                }}
              />
                <Button
                  type="submit" 
                  variant="outlined" 
                  color="primary"
                  className='mt-4 py-3 rounded-pill'>
                  Login
                </Button>
            </form>
        </div>
      </div>
    </>
  )
}

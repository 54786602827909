import { Icon } from '@material-ui/core'
import React from 'react'
import { NavLink } from 'react-router-dom'
import imgCaret from './icon-caret.svg'


const NavSideList = ({ menu, handleShowChildren, index, handleCloseMenuMobile, textIcon }) => {
  return (
    <>
      {menu.children ? (
        <>
          <li
            className={menu.childrenShow ? 'have-children show' : 'have-children'}>
            <span onClick={() => handleShowChildren(index)} className={'d-flex align-items-center'}>
              <Icon className='p-0 pr-4 mr-1' style={{ fontSize: 18 }}>{menu.textIcon}</Icon>{menu.text}
            </span>
            <ul className='children'>
              {menu.children.map((children, index) => (
                <li key={index}>
                  <NavLink to={children.path} onClick={handleCloseMenuMobile} exact className={'d-flex align-items-center'}>
                    <Icon className='p-0 pr-4 mr-1' style={{ fontSize: 18 }}>{children.textIcon}</Icon>{children.text}
                  </NavLink>
                </li>
              ))}
            </ul>
            <img src={imgCaret} alt="dropdown" className="arrow-down text-light" />
          </li>
        </>
      ) : (
          <li className="not-have-children">
            <NavLink to={menu.path} onClick={handleCloseMenuMobile} exact className={'d-flex align-items-center'}>
              <Icon className='p-0 pr-4 mr-1' style={{ fontSize: 18 }}>{menu.textIcon}</Icon>{menu.text}
            </NavLink>
          </li>
        )}
    </>
  )
}

export default NavSideList

import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField } from '@material-ui/core'
import { DateRangePicker, DateRange as DateRanges } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah, jwtDecode } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { Add, ArrowDownward, Cancel, CheckCircle, Close, Create, DateRange, Delete, Search, Visibility } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';
import DialogBoolean, { DialogBooleanContent } from '../../components/dialog/DialogBoolean'

const Transaksi = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('error');

  
  const [selectedId, setSelectedId] = useState();
  const [selectedIdResellerPurcasher, setSelectedIdResellerPurcasher] = useState();
  const [showDialogReset, setShowDialogReset] = useState(false);
  const [noResiBaru, setNoResiBaru] = useState('');

  const [search, setSearch] = useState('');

  const [tanggal, setTanggal] = useState('');
  const [showDialogDate, setShowDialogDate] = useState(false);
  const [selectionRange, setselectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  function handleDateRange() {
    setTanggal(`${moment(selectionRange.startDate).format('DD/MM/YYYY')} - ${moment(selectionRange.endDate).format('DD/MM/YYYY')}`)
    setShowDialogDate(false);
  }

  const [totalPagination, setTotalPagination] = useState(1);
  const [paginationOnPage, setPaginationOnPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [roleId, setRoleId] = useState();
  const [roleAdded, setRoleAdded] = useState(false);

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      name: 'Pembeli',
      selector: row => row.idreseller_pembeli,
      sortable: true,
      grow: 2,
      cell: row => (
        <div>
          <div className='font-weight-bold' style={{fontSize: 14}}>{row.idreseller_pembeli}</div>
          <div className='text-muted'>{row.nama_pembeli}</div>
        </div>
      ),
    },
    {
      name: 'Tanggal',
      selector: row => moment(row.created_at).format('DD-MM-YYYY'),
      sortable: true,
      cell: row => {
        let mom = moment(row.created_at).locale('id');
        return (
        <div>
          <div className='text-muted' style={{fontSize: 14}}>{mom.format('HH:mm')}</div>
          <div style={{fontSize: 14}}>{mom.format('DD MMM YYYY')}</div>
        </div>
      )},
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: '15em',
      cell: row => (
        <div className='d-flex flex-column'>
          {row.status == '1' && (
            <span 
              className="badge badge-pill badge-secondary">
              Pending
            </span>
          )}
          {row.status == '2' && (
            <span 
              className="badge badge-pill badge-warning">
              Approved
            </span>
          )}
          {row.status == '3' && (
            <span 
              className="badge badge-pill badge-success">
              Pesanan Selesai
            </span>
          )}
          {row.status == '4' && (
            <span 
              className="badge badge-pill badge-danger">
              Pesanan Dibatalkan
            </span>
          )}
        </div>
      ),
      style: {
        fontSize: 14,
      }
    },
    // {
    //   name: 'Invoice',
    //   selector: row => row.order_number,
    //   cell: row => (
    //     <div className='d-flex justify-content-end'>
    //       <Button 
    //         variant='outlined' 
    //         size='small' 
    //         color='default' 
    //         // onClick={() => downloadExcel2('SKG-1665391514569')}
    //         onClick={() => downloadExcel2(row.order_number)}
    //       >
    //         <small><ArrowDownward fontSize='small' /> Download</small>
    //       </Button>
    //     </div>
    //   ),
    // },
  ]);

  const getData = async (param_text, status, start, end, param_page) => {
    setIsLoading(true);
    axios
      .get(
        `/api/transactions?search=${param_text}&start=${start}&end=${end}&page=${param_page}`,
        {
          headers: {
            "x-access-token": ls.get("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then(response => {
        console.log(response);
        setData(response.data.values.data);
        setTotalPagination(response.data.values.pagination.totalpagination);
        setTotalData(response.data.values.pagination.totaldata);
        setIsLoading(false);
        // setPaginationOnPage(response.data.values.pagination.page);
        setPaginationOnPage(param_page);
        setRoleId(response.data.values.role_id);

        // history.push({
        //   location: `/users?page=${param_page}&search=${param_text}&status=${paramstatus}`
        // });
      })
      .catch(error => {
        //console.log(error);
        setIsLoading(false);
      });
  }

  function resetState() {
    setSearch('');
    setPaginationOnPage(1);
  }

  async function downloadExcel2(order_number) {
    // setDataexcel([]);
    setShowDialogReset(false);
    setIsDownloading(true);

    let sakagoToken = await axios.get(`/token-api/get-token`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': ls.get('token'),
      },
    })
      .then((res) => {
        console.log(res);  
        return res?.data?.values?.token
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        setAlertMessage('Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
        setIsDownloading(false);
        return false;
      })

    if (sakagoToken) {
      await axios.get(`/sakago/invoice/${order_number}`, {
        headers: {
          // 'Content-Type': 'application/json',
          // 'Accept': 'application/pdf',
          'Authorization': 'Bearer ' + sakagoToken,
        },
        responseType: 'blob',
      })
      .then(async function (response) {
        // handle success
        // setDataexcel(response.data.values.data)
        console.log(response);
        fileDownload(response.data, 'Invoice.pdf', 'application/pdf');
        // let blob = new Blob([response.data.values], {type: 'application/pdf'}),
        // url = window.URL.createObjectURL(blob);
  
        // window.open(url);
        
        setIsDownloading(false);
        // excelClick();
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        setAlertMessage('Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
        setIsDownloading(false);
      })
    } else {
      setAlertMessage('Terjadi Kesalahan');
      setAlertType('error');
      setShowAlert(true);
      setIsDownloading(false);
    }

  }


  useEffect(() => {
    resetState();
    getData('', '', '', '', 1);
    getRole();
    if (location?.state?.snackbar_message) {
      setAlertMessage(location?.state?.snackbar_message);
      setAlertType(location?.state?.snackbar_type);
      setShowAlert(true);
      window.history.replaceState({}, document.title);
    }
  }, []);

  function getRole() {
    let role_id = jwtDecode(ls.get('token')).role_id;

    if (role_id == 1) {
      //console.log('role ID:', role_id);
      setColumns(old => [
        {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
          width: '5em',
          style: {
            fontSize: 14,
          }
        },
      ].concat([...old].concat([
        {
          name: 'Action',
          grow: 2,
          right: true,
          cell: row => (
            <div className='d-flex justify-content-end'>
              <Button 
                variant='outlined' 
                size='small' 
                color='default' 
                onClick={() => history.push({
                  pathname: `/transaksi/detail/${row.id}`,
                  state: {
                    row: row
                  },
                })}
              >
                <small><Visibility fontSize='small' /> Details</small>
              </Button>
            </div>
          ),
        },
      ])));
    } else if (role_id != 1) {
      //console.log('TEst');
      setColumns(old => [
        ...old, 
        {
          name: 'Action',
          grow: 2,
          right: true,
          cell: row => (
            <div className='d-flex justify-content-end'>
              <Button 
                variant='outlined' 
                size='small' 
                color='default' 
                onClick={() => history.push({
                  pathname: `/transaksi/detail/${row.id}`,
                  state: {
                    row: row
                  },
                })}
              >
                <small><Visibility fontSize='small' /> Details</small>
              </Button>
              {row.status == '1' ? (
                <>
                  <div style={{width: '0.5em', height: '0.5em'}} />
                  <Button 
                    variant='outlined' 
                    size='small' 
                    className='text-primary border-primary'
                    onClick={() => {
                      handleChangeStatus(row.id, 2, row.idseller_purchaser);
                    }}
                  >
                    <small><CheckCircle fontSize='small' /> Approve</small>
                  </Button>
                </>
              ) : row.status == '2' ? (
                <>
                  <div style={{width: '0.5em', height: '0.5em'}} />
                  <Button 
                    variant='outlined' 
                    size='small' 
                    className='text-success border-success'
                    onClick={() => {
                      handleChangeStatus(row.id, 3, row.idseller_purchaser);
                    }}
                  >
                    <small><CheckCircle fontSize='small' /> Selesai</small>
                  </Button>
                </>
              ) : row.status != '3' && row.status != '4' ? (
                <>
                  <div style={{width: '0.5em', height: '0.5em'}} />
                  <Button 
                    variant='outlined' 
                    size='small' 
                    className='text-danger border-danger'
                    onClick={() => {
                      handleChangeStatus(row.id, 4, row.idseller_purchaser);
                    }}
                  >
                    <small><Cancel fontSize='small' /> Batalkan</small>
                  </Button>
                </>
              ) : null}
              <div style={{width: '0.5em', height: '0.5em'}} />
                <Button 
                  variant='outlined' 
                  size='small' 
                    className='text-secondary border-secondary'
                  onClick={() => {
                    setSelectedId(row.id);
                    setSelectedIdResellerPurcasher(row.idseller_purchaser);
                    setShowDialogReset(true);
                  }}
                >
                  <small><Create fontSize='small' /> Edit No Resi</small>
                </Button>
            </div>
          ),
        }
      ])
    }
  }
  
  function removeSearch() {
    setSearch('');
  }

  function searchHandler() {
    let temp1 = tanggal !== '' ? moment(selectionRange.startDate).format('YYYY-MM-DD') : '';
    let temp2 = tanggal !== '' ? moment(selectionRange.endDate).format('YYYY-MM-DD') : '';
    getData(search, '', temp1, temp2, paginationOnPage);
  }

  function nextPage(page) {
    let temp1 = tanggal !== '' ? moment(selectionRange.startDate).format('YYYY-MM-DD') : '';
    let temp2 = tanggal !== '' ? moment(selectionRange.endDate).format('YYYY-MM-DD') : '';
    getData(search, '', temp1, temp2, page);
  }

  async function handleEditNoResi(val) {
    setShowDialogReset(false);
    setIsLoading(true);
    //console.log(val);
    await axios({
      method: "put",
      url: "/api/transactions/update-noresi/"+selectedId,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
      data: {
        no_resi: val,
        idseller_purchaser: selectedIdResellerPurcasher,
      }
    })
      .then(response => {
        //console.log(response);
        setIsLoading(false);
        setAlertMessage('No Resi Berhasil Diubah');
        setAlertType('success');
        setShowAlert(true);
        let temp1 = tanggal !== '' ? moment(selectionRange.startDate).format('YYYY-MM-DD') : '';
        let temp2 = tanggal !== '' ? moment(selectionRange.endDate).format('YYYY-MM-DD') : '';
        getData(search, '', temp1, temp2, paginationOnPage);
      })
      .catch(error => {
        //console.log(error);
        setAlertMessage('Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
      });
  }

  async function handleChangeStatus(id, val, idseller_purchaser) {
    setShowDialogReset(false);
    setIsLoading(true);
    //console.log(val);
    await axios({
      method: "put",
      url: "/api/transactions/update-status/"+id,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token")
      },
      data: {
        status: val,
        idseller_purchaser: idseller_purchaser,
      }
    })
      .then(response => {
        //console.log(response);
        setIsLoading(false);
        setAlertMessage('Update Berhasil');
        setAlertType('success');
        setShowAlert(true);
        let temp1 = tanggal !== '' ? moment(selectionRange.startDate).format('YYYY-MM-DD') : '';
        let temp2 = tanggal !== '' ? moment(selectionRange.endDate).format('YYYY-MM-DD') : '';
        getData(search, '', temp1, temp2, paginationOnPage);
      })
      .catch(error => {
        //console.log(error);
        setAlertMessage('Terjadi Kesalahan');
        setAlertType('error');
        setShowAlert(true);
      });
  }

  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />
      <Loader show={isDownloading} title="Downloading..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={showDialogReset}
        onClose={() => setShowDialogReset(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{paddingBottom: 0}}>Edit No Resi</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            type="text"
            placeholder="No Resi"
            value={noResiBaru}
            onChange={e => setNoResiBaru(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowDialogReset(false)} color="primary">
              Kembali
            </Button>
            <Button onClick={() => handleEditNoResi(noResiBaru)} color="primary" autoFocus>
              Simpan
            </Button>
        </DialogActions>
      </Dialog>

      <DialogBooleanContent
        open={showDialogDate} 
        handleClose={() => setShowDialogDate(false)} 
        title="Pilih tanggal" 
        content={
          <DateRanges 
            locale={localeId} 
            ranges={[selectionRange]} 
            onChange={(DateRange) => setselectionRange(DateRange.range1)} 
            maxDate={new Date()} 
            editableDateInputs={true} 
            dateDisplayFormat="d MMMM yyyy" 
          />
        }
        onClickYes={handleDateRange}
      />

      {/* <Fab color="primary" aria-label="add" style={{position: 'fixed', right: '3em', bottom: '3em', zIndex: 9999, backgroundColor: blue[500]}} className="d-block d-sm-none" onClick={() => history.push('/users/tambah')}>
        <Add />
      </Fab> */}

      <Grid container>
        <Grid item xs={12} className="mb-3">
          <h3>Transaksi</h3>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className="drop-shadow p-3 mb-4">
            <div className='d-flex flex-column flex-sm-row justify-content-sm-end justify-content-start'>
              {/* <Button 
                variant="contained" 
                size='small'
                className='mr-auto d-none d-sm-block' 
                style={{backgroundColor: blue[500], color: 'white'}}
                disableElevation={true}
                onClick={() => history.push('/users/tambah')}
              >
                <Add /> Tambah
              </Button> */}

              <TextField 
                type="text"  
                label="Tanggal" 
                variant="outlined"
                size="small"
                value={tanggal}
                onClick={() => setShowDialogDate(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateRange color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <div className='mr-1 mb-1' />
              <TextField 
                type="search" 
                id="outlined-basic" 
                label="Search" 
                variant="outlined"
                size="small"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button 
                        size='small' 
                        variant='text' 
                        style={{minWidth: 0, opacity: search !== '' ? 1 : 0}}
                        disabled={search !== '' ? false : true} 
                        className="text-muted rounded-circle" 
                        onClick={removeSearch}
                      >
                        <Close fontSize='small' />
                      </Button>
                      <Box bgcolor={blue[500]} className='text-white p-2 search-button' onClick={searchHandler}>
                        <Search color='inherit' />
                      </Box>
                    </InputAdornment>
                  ),
                  style: {paddingRight: 0, overflow: 'hidden'},
                  color: blue[500]
                }}
              />
            </div>
              <DataTable
                columns={columns}
                data={data}
                // selectableRows
                pagination
                paginationServer
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                }}
                onChangePage={page => nextPage(page)}
                paginationTotalRows={totalData}
                paginationPerPage={20}
              />
            <div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(Transaksi)

import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Grid, Icon, Paper, Snackbar } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import { errorHandler } from '../../helpers/errorHandler'
import MuiAlert from '@material-ui/lab/Alert';
import { DateRange as DateRangeIcon, Event as EventIcon, Fastfood, MonetizationOn } from '@material-ui/icons'

const Dashboard = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('error');

  const [totalProduct, setTotalProduct] = useState('');
  const [totalProductToday, setTotalProductToday] = useState('');
  const [totalTransaction, setTotalTransaction] = useState('');
  const [totalTransactionToday, setTotalTransactionToday] = useState('');

  const getData = () => {
    axios
      .get(
        `/api/dashboard`,        {
          headers: {
            "x-access-token": ls.get("token"),
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        //console.log(response?.data?.values?.total_product);
        
        setTotalProduct(response?.data?.values?.total_product);
        setTotalProductToday(response?.data?.values?.total_product_today);
        setTotalTransaction(response?.data?.values?.total_transaction);
        setTotalTransactionToday(response?.data?.values?.total_transaction_today);

      })
      .catch(error => {
        //console.log(error);
      });
  };

  useEffect(() => {
    getData();
    if (location?.state?.snackbar_message) {
      //console.log('show snackbar');
      setAlertMessage(location?.state?.snackbar_message);
      setAlertType(location?.state?.snackbar_type);
      setShowAlert(true);
      window.history.replaceState({}, document.title);
    }
  }, [])
  

  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <Grid container>
        <Grid item xs={12} className="mb-3">
          <h3>Dashboard</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Paper elevation={0} className="drop-shadow p-3 d-flex flex-row w-100">
                <EventIcon className='text-primary mr-2' style={{fontSize: 50}} />
                <div>
                  <div style={{fontSize: 25}} className="font-weight-bold">
                    {getRupiah(totalProductToday)}
                  </div>
                  <div>Total Produk Hari Ini</div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={0} className="drop-shadow p-3 d-flex flex-row w-100">
                <Fastfood className='text-primary mr-2' style={{fontSize: 50}} />
                <div>
                  <div style={{fontSize: 25}} className="font-weight-bold">
                    {getRupiah(totalProduct)}
                  </div>
                  <div>Total Produk</div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={0} className="drop-shadow p-3 d-flex flex-row w-100">
                <DateRangeIcon className='text-primary mr-2' style={{fontSize: 50}} />
                <div>
                  <div style={{fontSize: 25}} className="font-weight-bold">
                    {getRupiah(totalTransactionToday)}
                  </div>
                  <div>Total Transaksi Hari Ini</div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={0} className="drop-shadow p-3 d-flex flex-row w-100">
                <MonetizationOn className='text-primary mr-2' style={{fontSize: 50}} />
                <div>
                  <div style={{fontSize: 25}} className="font-weight-bold">
                    {getRupiah(totalTransaction)}
                  </div>
                  <div>Total Transaksi</div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(Dashboard)

export function minNumberV(val, min) {
  if (val < min) {
    return {error: true, message: `Angka tidak boleh kurang dari ${min}`};
  }
  return {error: false, message: ''};
}

export function maxNumberV(val, max) {
  if (val > max) {
    return {error: true, message: `Angka tidak boleh lebih dari ${max}`};
  }
  return {error: false, message: ''};
}

export function minLengthV(val, min) {
  if (val?.length < min) {
    return {error: true, message: `Form minimal memiliki ${min} karakter`};
  }
  return {error: false, message: ''};
}

export function maxLengthV(val, max) {
  if (val?.length > max) {
    return {error: true, message: `Form maksimal memiliki ${max} karakter`};
  }
  return {error: false, message: ''};
}

export function notNullV(val) {
  if (val?.length === 0 || val === undefined) {
    return {error: true, message: `Form tidak boleh kosong`};
  }
  return {error: false, message: ''};
}

export function onlyNumberV(val) {
  let pattern = /\d/;
  if (!pattern.test(val)) {
    return {error: true, message: `Form hanya boleh angka`};
  }
  return {error: false, message: ''};
}

export function usernameV(val) {
  let pattern = /^[a-zA-Z0-9_ ]*$/;
  if (!pattern.test(val)) {
    return {error: true, message: `Form hanya boleh huruf, angka dan _`};
  }
  return {error: false, message: ''};
}

export function nameV(val) {
  let pattern = /^[a-zA-Z ]*$/;
  if (!pattern.test(val)) {
    return {error: true, message: `Form hanya boleh huruf`};
  }
  return {error: false, message: ''};
}

export function phoneNumberV(val) {
  let pattern = /([+][0-9]{12,15})|([0-9]{10,13})/;
  if (!pattern.test(val)) {
    return {error: true, message: `Normor HP invalid`};
  }
  return {error: false, message: ''};
}

export function emailV(val) {
  let pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  if (!pattern.test(val)) {
    return {error: true, message: `Email invalid`};
  }
  return {error: false, message: ''};
}

export function passwordV(val) {
  let pattern = /^(?=.*\d)(?=.*[a-zA-Z]).{6,99}$/;
  if (!pattern.test(val)) {
    return {error: true, message: `Password minimal 6 karakter, 1 huruf dan 1 angka`};
  }
  return {error: false, message: ''};
}

export function regexV(val, regex, message = "Form Invalid") {
  let pattern = regex;
  if (!pattern.test(val)) {
    return {error: true, message: message};
  }
  return {error: false, message: ''};
}

export function fileFormatV(file, format = []) {
  if (file === undefined) {
    return {error: false, message: ''};
  }

  let temp = file?.name.split('.');
  let type = temp[temp.length-1];
  
  if (!format.includes(type)) {
    return {error: true, message: 'Format file tidak valid'};
  }
  return {error: false, message: ''};
}

// export default function checkValidationV(array) {
//   let result = true;
//   array.forEach(item => {
//     if (item.error) {
//       result = false;
//     }
//   });
//   return result;
// }

export function joinV(array) {
  let result = false;
  let message = [];

  array.forEach(item => {
    result = result | item.error
    if (item.error) {
      message.push(item.message);
    }
  });
  return {error: result, message: message.join('; ')};
}
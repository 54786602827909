import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls, { set } from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Checkbox, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { AccountCircleOutlined, Add, AllInbox, ArrowDownward, ArrowUpward, CheckBoxOutlineBlank, Close, Delete, FastfoodOutlined, ImageOutlined, Inbox, LocationOn, LockOutlined, MailOutline, Map, Save, Search, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { fileFormatV, joinV, minLengthV, minNumberV, notNullV } from '../../helpers/validation'


const Tambah = (props) => {
  const urlParams = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('asd');
  const [alertType, setAlertType] = useState('error');

  const [categoriList, setCategoriList] = useState([]);
  const [subCategoriList, setSubCategoriList] = useState([]);

  const [image, setImage] = useState();
  const [name, setName] = useState('');
  const [categori, setCategori] = useState('');
  const [subCategori, setSubCategori] = useState('');
  const [harga, setHarga] = useState('');
  const [berat, setBerat] = useState('');
  const [deskripsi, setDeskripsi] = useState('');
  // const [tampilDiDepan, setTampilDiDepan] = useState('');
  const [status, setStatus] = useState('');
  const [stok, setStok] = useState('');
  const [maxBeli, setMaxBeli] = useState('');
  const [minBeli, setMinBeli] = useState('');

  const [validation, setValidation] = useState({
    image: {},
    name: {},
    categori: {},
    subCategori: {},
    harga: {},
    berat: {},
    // tampilDiDepan: {},
    status: {},
    stok: {},
    maxBeli: {},
    minBeli: {},
  })

  useEffect(() => {
    getAllData();
  }, []);

  async function getCategori() {
    await axios({
      method: "get",
      url: "/api/category/egrocery/",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('Categori:', response.data);
        setCategoriList(response.data.values.data);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  async function getSubCategori(parent_id) {
    await axios({
      method: "get",
      url: "/api/category/egrocery/"+parent_id,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
    })
      .then((response) => {
        //console.log('Categori:', response.data);
        setSubCategoriList(response.data.values.data);
      })

      .catch((error) => {
        //console.log(error);
      });
  }

  function getAllData() {
    setIsLoading(true);
    Promise.all([getCategori()]).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    })
  }

  function handleSubmit() {
    // e.preventDefault();
    // if (
    //   name === "" ||
    //   image === undefined ||
    //   categori === "" ||
    //   subCategori === "" ||
    //   status === "" ||
    //   // tampilDiDepan === "" ||
    //   deskripsi === "" ||
    //   harga === ""
    // ) {
    //   setAlertType('error');
    //   setAlertMessage('Lengkapi data');
    //   setShowAlert(true);
    //   return false;
    // }

    let vt = {
      image: joinV([fileFormatV(image, ['png', 'jpg', 'jpeg']), notNullV(image)]),
      name: minLengthV(name, 3),
      categori: notNullV(categori),
      subCategori: notNullV(subCategori),
      harga: joinV([minNumberV(harga, 0), notNullV(harga)]),
      berat: joinV([minNumberV(berat, 0), notNullV(berat)]),
      status: notNullV(status),
      stok: joinV([minNumberV(stok, 0), notNullV(stok)]),
      maxBeli: joinV([minNumberV(maxBeli, 1), notNullV(maxBeli)]),
      minBeli: joinV([minNumberV(minBeli, 1), notNullV(minBeli)]),
    }
    setValidation(vt);
    if (vt.image.error || vt.name.error || vt.categori.error || vt.subCategori.error || vt.harga.error || vt.berat.error || vt.status.error || vt.stok.error || vt.maxBeli.error || vt.minBeli.error) {
      return false;
    }

    setIsLoading(true);
    let data = new FormData();
    data.append("image", image);
    data.append("description", deskripsi);
    data.append("price", harga);
    data.append("name", name);
    data.append("category_id", subCategori);
    // data.append("in_front", tampilDiDepan);
    data.append("in_front", '0');
    data.append("weight", berat);
    data.append("max_buy", maxBeli);
    data.append("min_buy", minBeli);
    data.append("stock", stok);
    data.append("sort", "");
    data.append("active", status);

    axios({
      method: "post",
      url: "/api/products",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
      data
    })
      .then((response) => {
        if (response.data.values.success) {
          history.push({
            pathname: '/egrocery/produk',
            state: {snackbar_message: 'Data Berhasil Ditambakan', snackbar_type: 'success'}
          });
        } else {
          setAlertType('error');
          setAlertMessage(response.data.values.message);
          setShowAlert(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        //console.log(error.response);
        setAlertType('error');
        setAlertMessage(error?.response?.data?.values?.message ? error?.response?.data?.values?.message : 'Terjadi Kesalahan');
        setShowAlert(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      {/* <form onSubmit={handleSubmit}> */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={10} xl={8}>
          <h3>Tambah Produk E-Grocery</h3>
        </Grid>
        <div className="d-none d-xl-flex w-100" />
        <Grid item xs={12} sm={6} md={5} xl={4}>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
            <h4 className='mb-3'>Gambar</h4>
            <div className='d-flex flex-column align-items-stretch'>
              {image && (
                <img src={image ? URL.createObjectURL(image) : ''} style={{maxHeight: 300, maxWidth: '80%'}} className="mb-3 mx-3 align-self-center" alt="gambar"/>
              )}
              <TextField
                type="file" 
                name="image" 
                variant="outlined" 
                label="Gambar" 
                className="mb-3"
                // value={image}
                onChange={(e) => setImage(e.target.files[0])}
                error={validation?.image?.error}
                helperText={validation?.image?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ImageOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Paper>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
            <h4 className='mb-3'>Produk</h4>
            <TextField
              type="text" 
              name="name" 
              variant="outlined" 
              label="Nama" 
              className="mb-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={validation?.name?.error}
              helperText={validation?.name?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FastfoodOutlined color='disabled' />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl variant='outlined' className='mb-3' error={validation?.categori?.error}>
              <InputLabel id="categori_label">Kelompok</InputLabel>
              <Select
                labelId="categori_label"
                label="Kelompok"
                name="categori"
                value={categori}
                onChange={e => {setCategori(e.target.value); setSubCategori(''); getSubCategori(e.target.value);}}
              >
                {categoriList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validation?.categori?.message}</FormHelperText>
            </FormControl>
            <FormControl variant='outlined' className='mb-3' error={validation?.subCategori?.error}>
              <InputLabel id="sub_categori_label">Kategori</InputLabel>
              <Select
                labelId="sub_categori_label"
                label="Kategori"
                name="sub_categori"
                value={subCategori}
                onChange={e => setSubCategori(e.target.value)}
              >
                {subCategoriList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validation?.subCategori?.message}</FormHelperText>
            </FormControl>
            <TextField
              type="number" 
              name="harga" 
              variant="outlined" 
              label="Harga" 
              className="mb-3"
              value={harga}
              onChange={(e) => setHarga(e.target.value)}
              error={validation?.harga?.error}
              helperText={validation?.harga?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className='text-muted'>
                    Rp
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="number" 
              name="berat" 
              variant="outlined" 
              label="Berat" 
              className="mb-3"
              value={berat}
              onChange={(e) => setBerat(e.target.value)}
              error={validation?.berat?.error}
              helperText={validation?.berat?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className='text-muted'>
                    <AllInbox color='disabled' />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
            <h4 className='mb-3'>Deskripsi</h4>
            <CKEditor
              editor={ ClassicEditor }
              data={deskripsi}
              onChange={ ( event, editor ) => {
                setDeskripsi(editor.getData());
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={5} xl={4}>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
            <h4 className='mb-3'>Detail</h4>
            <FormControl variant='outlined' className='mb-3' error={validation?.status?.error}>
              <InputLabel id="status_label">Status</InputLabel>
              <Select
                labelId="status_label"
                label="Status"
                name="status"
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                <MenuItem value="1">Aktif</MenuItem>
                <MenuItem value="0">Tidak Aktif</MenuItem>
              </Select>
              <FormHelperText>{validation?.status?.message}</FormHelperText>
            </FormControl>
            {/* <FormControl variant='outlined' className='mb-3'>
              <InputLabel id="tampil_depan_label">Tampil di depan</InputLabel>
              <Select
                labelId="tampil_depan_label"
                label="Tampil di depan"
                name="tampil_di_depan"
                value={tampilDiDepan}
                onChange={e => setTampilDiDepan(e.target.value)}
              >
                <MenuItem value="1">Ya</MenuItem>
                <MenuItem value="0">Tidak</MenuItem>
              </Select>
            </FormControl> */}
            <TextField
              type="number" 
              name="stok" 
              variant="outlined" 
              label="Stok" 
              className="mb-3"
              value={stok}
              onChange={(e) => setStok(e.target.value)}
              error={validation?.stok?.error}
              helperText={validation?.stok?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className='text-muted'>
                    <Inbox color='disabled' />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="number" 
              name="max_beli" 
              variant="outlined" 
              label="Max Beli" 
              className="mb-3"
              value={maxBeli}
              onChange={(e) => setMaxBeli(e.target.value)}
              error={validation?.maxBeli?.error}
              helperText={validation?.maxBeli?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className='text-muted'>
                    <ArrowUpward color='disabled' />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="number" 
              name="min_beli" 
              variant="outlined" 
              label="Min Beli" 
              className="mb-3"
              value={minBeli}
              onChange={(e) => setMinBeli(e.target.value)}
              error={validation?.minBeli?.error}
              helperText={validation?.minBeli?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className='text-muted'>
                    <ArrowDownward color='disabled' />
                  </InputAdornment>
                ),
              }}
            />
            
            <div className="d-flex flex-row justify-content-end mt-3">
                <Button 
                  variant='contained'
                  className='bg-primary text-white align-items-center' 
                  onClick={handleSubmit}
                >
                  <Save /> Simpan
                </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {/* </form> */}
    </>
  )
}

export default React.memo(Tambah)

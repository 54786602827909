import React, { useState, useEffect, createRef, useRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField } from '@material-ui/core'
import { DateRangePicker, DateRange as DateRanges } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah, jwtDecode } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { Add, ArrowDownward, Cancel, CheckCircle, Close, CloudDownload, Create, DateRange, Delete, Search, Visibility } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';
import DialogBoolean, { DialogBooleanContent } from '../../components/dialog/DialogBoolean'
import { CSVLink } from 'react-csv'
import { useCallback } from 'react'
import { Spinner } from 'react-bootstrap'

const Cicilan = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('error');

  
  const [selectedId, setSelectedId] = useState();
  const [selectedIdResellerPurcasher, setSelectedIdResellerPurcasher] = useState();
  const [showDialogReset, setShowDialogReset] = useState(false);
  const [noResiBaru, setNoResiBaru] = useState('');

  const [search, setSearch] = useState('');

  const [tanggal, setTanggal] = useState('');
  const [showDialogDate, setShowDialogDate] = useState(false);
  const [selectionRange, setselectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  function handleDateRange() {
    setTanggal(`${moment(selectionRange.startDate).format('DD/MM/YYYY')} - ${moment(selectionRange.endDate).format('DD/MM/YYYY')}`)
    setShowDialogDate(false);
  }

  const [totalPagination, setTotalPagination] = useState(1);
  const [paginationOnPage, setPaginationOnPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [roleId, setRoleId] = useState();
  const [roleAdded, setRoleAdded] = useState(false);

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      name: 'Calon Jemaah',
      selector: row => row.name,
      sortable: true,
      grow: 2,
      cell: row => (
        <div>
          <div className='font-weight-bold' style={{fontSize: 14}}>{row.phone}</div>
          <div className='text-muted'>{row.name}({row.reseller_id})</div>
        </div>
      ),
    },
    {
      name: 'Produk',
      selector: row => row.product_name,
      sortable: true,
      grow: 2,
      cell: row => (
        <div>
          <div className='font-weight-bold' style={{fontSize: 14}}>{row.product_name}</div>
          <div className='text-muted'>Rp{getRupiah(row.price)}</div>
        </div>
      ),
    },
    {
      name: 'Dana Terkumpul',
      selector: row => 'Rp' + getRupiah(row.collected),
      sortable: true,
      style: {
        fontSize: 14,
      }
    },
    {
      name: 'Tanggal',
      selector: row => moment(row.created_at).format('DD-MM-YYYY'),
      sortable: true,
      cell: row => {
        let mom = moment(row.created_at).locale('id');
        return (
        <div>
          <div className='text-muted' style={{fontSize: 14}}>{mom.format('HH:mm')}</div>
          <div style={{fontSize: 14}}>{mom.format('DD MMM YYYY')}</div>
        </div>
      )},
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: '15em',
      cell: row => (
        <div className='d-flex flex-column'>
          {row.status == '1' && (
            <span 
              className="badge badge-pill badge-secondary">
              Progress
            </span>
          )}
          {row.status == '2' && (
            <span 
              className="badge badge-pill badge-warning">
              Telah dibeli
            </span>
          )}
          {row.status == '0' && (
            <span 
              className="badge badge-pill badge-danger">
              Gagal
            </span>
          )}
        </div>
      ),
      style: {
        fontSize: 14,
      }
    },
    // {
    //   name: 'Action',
    //   grow: 2,
    //   right: true,
    //   cell: row => (
    //     <div className='d-flex justify-content-end'>
    //       <Button 
    //         variant='outlined' 
    //         size='small' 
    //         color='default' 
    //         onClick={() => history.push({
    //           pathname: `/cicilan/detail/${row.cicilan_id}`,
    //           state: row,
    //         })}
    //       >
    //         <small><Visibility fontSize='small' /> Details</small>
    //       </Button>
    //     </div>
    //   ),
    // },
  ]);

  const getData = async (param_text, status, start, end, param_page) => {
    setIsLoading(true);
    axios
      .get(
        `/api/cicilan-travel?search=${param_text}&start=${start}&end=${end}&page=${param_page}`,
        {
          headers: {
            "x-access-token": ls.get("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then(response => {
        console.log(response);
        setData(response.data.values.data);
        setTotalPagination(response.data.values.pagination.totalpagination);
        setTotalData(response.data.values.pagination.totaldata);
        setIsLoading(false);
        // setPaginationOnPage(response.data.values.pagination.page);
        setPaginationOnPage(param_page);
        setRoleId(response.data.values.role_id);

        // history.push({
        //   location: `/users?page=${param_page}&search=${param_text}&status=${paramstatus}`
        // });
      })
      .catch(error => {
        //console.log(error);
        setIsLoading(false);
      });
  }

  function getRole() {
    let role_id = jwtDecode(ls.get('token')).role_id;

    if (role_id == 1) {
      setColumns(old => [
        {
            name: 'Provider',
            selector: row => row.provider,
            sortable: true,
            cell: row => (
              <div>
                <div className='text-muted'>{row.user_id}</div>
                <div style={{fontSize: 14}}>{row.provider}</div>
              </div>
            ),
        },
        ...old
      ]);
    }
  }

  function resetState() {
    setSearch('');
    setPaginationOnPage(1);
  }


  useEffect(() => {
    resetState();
    getRole();
    getData('', '', '', '', 1);
    if (location?.state?.snackbar_message) {
      setAlertMessage(location?.state?.snackbar_message);
      setAlertType(location?.state?.snackbar_type);
      setShowAlert(true);
      window.history.replaceState({}, document.title);
    }
  }, []);
  
  function removeSearch() {
    setSearch('');
  }

  function searchHandler() {
    let temp1 = tanggal !== '' ? moment(selectionRange.startDate).format('YYYY-MM-DD') : '';
    let temp2 = tanggal !== '' ? moment(selectionRange.endDate).format('YYYY-MM-DD') : '';
    getData(search, '', temp1, temp2, paginationOnPage);
  }

  function nextPage(page) {
    let temp1 = tanggal !== '' ? moment(selectionRange.startDate).format('YYYY-MM-DD') : '';
    let temp2 = tanggal !== '' ? moment(selectionRange.endDate).format('YYYY-MM-DD') : '';
    getData(search, '', temp1, temp2, page);
  }

  const handleDownload = useCallback(
    async () => {
      if (!isDownloading) {
        setIsDownloading(true);
        let temp1 = tanggal !== '' ? moment(selectionRange.startDate).format('YYYY-MM-DD') : '';
        let temp2 = tanggal !== '' ? moment(selectionRange.endDate).format('YYYY-MM-DD') : '';
        await axios
          .get(
            `/api/cicilan-travel/download?search=${search}&start=${temp1}&end=${temp2}`,
            {
              headers: {
                "x-access-token": ls.get("token"),
                "Content-Type": "application/json",
              },
            }
          )
          .then(response => {
            console.log(response.data.values.data);
            setDownloadedData(response.data.values.data);
            setIsDownloading(false);
          })
          .catch(error => {
            setIsDownloading(false);
          });
      }
    },
    [isDownloading, search, selectionRange, tanggal],
  );

  useEffect(() => {
    if (downloadedData.length > 0 && csvDownloadRef.current && !isDownloading) {
      csvDownloadRef.current.link.click();
    }
  }, [downloadedData.length, isDownloading]);

  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <DialogBooleanContent
        open={showDialogDate} 
        handleClose={() => setShowDialogDate(false)} 
        title="Pilih tanggal" 
        content={
          <DateRanges 
            locale={localeId} 
            ranges={[selectionRange]} 
            onChange={(DateRange) => setselectionRange(DateRange.range1)} 
            maxDate={new Date()} 
            editableDateInputs={true} 
            dateDisplayFormat="d MMMM yyyy" 
          />
        }
        onClickYes={handleDateRange}
      />

      <Fab color="primary" aria-label="add" style={{position: 'fixed', right: '3em', bottom: '3em', zIndex: 9999, backgroundColor: blue[500]}} className="d-block d-sm-none align-items-center justify-content-center" onClick={handleDownload}>
        {isDownloading ? (
          <Spinner animation="border" role="status"/>
        ) : (
          <CloudDownload />
        )}
      </Fab>

      <Grid container>
        <Grid item xs={12} className="mb-3">
          <h3>Cicilan</h3>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className="drop-shadow p-3 mb-4">
            <div className='d-flex flex-column flex-sm-row justify-content-sm-end justify-content-start'>
              <Button 
                variant="contained" 
                size='small'
                className='mr-auto d-none d-sm-block align-items-center justify-content-center' 
                style={{backgroundColor: blue[500], color: 'white'}}
                disableElevation={true}
                onClick={handleDownload}
              >
                {isDownloading ? (
                  <>
                    <Spinner animation="border" role="status" size="sm"/> Downloading...
                  </>
                ) : (
                  <>
                    <CloudDownload /> Download
                  </>
                )}
              </Button>
              <CSVLink
                className='d-none' 
                filename={"cicilan.csv"}
                data={downloadedData}
                ref={csvDownloadRef}
              >
              </CSVLink>

              <TextField 
                type="text"  
                label="Tanggal" 
                variant="outlined"
                size="small"
                value={tanggal}
                onClick={() => setShowDialogDate(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateRange color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
              <div className='mr-1 mb-1' />
              <TextField 
                type="search" 
                id="outlined-basic" 
                label="Search" 
                variant="outlined"
                size="small"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button 
                        size='small' 
                        variant='text' 
                        style={{minWidth: 0, opacity: search !== '' ? 1 : 0}}
                        disabled={search !== '' ? false : true} 
                        className="text-muted rounded-circle" 
                        onClick={removeSearch}
                      >
                        <Close fontSize='small' />
                      </Button>
                      <Box bgcolor={blue[500]} className='text-white p-2 search-button' onClick={searchHandler}>
                        <Search color='inherit' />
                      </Box>
                    </InputAdornment>
                  ),
                  style: {paddingRight: 0, overflow: 'hidden'},
                  color: blue[500]
                }}
              />
            </div>
              <DataTable
                columns={columns}
                data={data}
                // selectableRows
                pagination
                paginationServer
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                }}
                onChangePage={page => nextPage(page)}
                paginationTotalRows={totalData}
                paginationPerPage={20}
              />
            <div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(Cicilan)

import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Box, Button, Checkbox, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Tab, TextField } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory, useParams } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { AccountCircleOutlined, Add, Close, Delete, LocationOn, LockOutlined, MailOutline, Map, Save, Search, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import DataTable from 'react-data-table-component';
import MuiAlert from '@material-ui/lab/Alert';


const Tambah = (props) => {
  const urlParams = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('asd');
  const [alertType, setAlertType] = useState('error');

  const [roleList, setRoleList] = useState([]);
  const [provinsiList, setProvinsiList] = useState([]);
  const [kotaList, setKotaList] = useState([]);
  const [kurirList, setKurirList] = useState([]);

  const [name, setName] = useState('');

  function handleSubmit() {
    // e.preventDefault();
    if (
      name === ""
    ) {
      setAlertType('error');
      setAlertMessage('Lengkapi data');
      setShowAlert(true);
      return false;
    }

    axios({
      method: "post",
      url: "/api/admin/",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ls.get("token"),
      },
      data: {
        name: name,
      },
    })
      .then((response) => {
        history.push({
          pathname: '/role',
          state: {snackbar_message: 'Data Berhasil Ditambakan', snackbar_type: 'success'}
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  return (
    <>
      <Loader show={isLoading} title="Tunggu..." />

      <Snackbar
        open={showAlert} 
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        // message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      {/* <form onSubmit={handleSubmit}> */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={10} xl={8}>
          <h3>Tambah User</h3>
        </Grid>
        <div className="d-none d-xl-flex w-100" />
        <Grid item xs={12} sm={6} md={5} xl={4}>
          <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column">
            <h4 className='mb-3'>Role</h4>
            <TextField
              type="text" 
              name="name" 
              variant="outlined" 
              label="Nama" 
              className="mb-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlined color='disabled' />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
          {/* <Paper elevation={0} className="drop-shadow p-3 d-flex flex-column mt-3">
            <h4 className='mb-3'>Hak Akses</h4>
            
          </Paper> */}
        </Grid>
      </Grid>
      {/* </form> */}
    </>
  )
}

export default React.memo(Tambah)

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogBoolean(props) {   
  const {open, handleClose, title, content, onClickYes} = props;

  function onClickY() {
    onClickYes();
    handleClose();
  }

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClickY} color="primary" autoFocus>
          Yes
        </Button>
    </DialogActions>
    </Dialog>
  );
}

export function DialogBooleanContent(props) {   
  const {open, handleClose, title, content, onClickYes} = props;

  function onClickY() {
    onClickYes();
    handleClose();
  }

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {content}
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClickY} color="primary" autoFocus>
          Yes
        </Button>
    </DialogActions>
    </Dialog>
  );
}

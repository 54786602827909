import React from 'react'
import styles from './loader.module.css'
import imgLoader from './loading.svg'

// props show true or false

const Loader = ({ show, title }) => {
  if (show) {
    return (
      <div className={styles.overlay}>
        <img
          className={styles.img}
          src={imgLoader}
          alt="loading..." />
        {title && (
          <p className={styles.title}>{title}</p>
        )}
      </div>
    )
  }
  return null;
}

export default Loader

import React, { useState, useEffect } from 'react';
import './layout.scss';
import Drawer from './drawer/Drawer';
import Navbar from './navbar/Navbar';
import Breadcrumb from './breadcrumb/Breadcrumb';
import NavSide from '../NavSide/NavSide'
import { withRouter } from 'react-router-dom'
import ls from 'local-storage'

const Layout = (props) => {

  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const handleShowMenuMobile = () => setShowMenuMobile(true);
  const handleCloseMenuMobile = () => setShowMenuMobile(false);

  // useEffect(() => {
  //   fetch('/api/account/summary', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'x-access-token': ls.get('token'),
  //     }
  //   })
  //     .then((res) => {
  //       if (res.status === 401) {
  //         props.history.push('/login');
  //         ls.remove('token');
  //         ls.remove('isLogin');
  //       }
  //     })
  // }, [])

  return (
    <>
      {/* sidenav */}
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
      <div className="sidenav" style={showMenuMobile ? { marginLeft: '0' } : null}>
        <Drawer />
        <NavSide handleCloseMenuMobile={handleCloseMenuMobile} />
      </div>
      {/* end sidenav */}
      <div className={showMenuMobile ? 'main-page position-fixed bg-light' : 'main-page bg-light'} style={{ marginTop: '60px' }}>
        <Navbar
          handleCloseMenuMobile={handleCloseMenuMobile}
          handleShowMenuMobile={handleShowMenuMobile}
        />
        {/* <Breadcrumb /> */}
        <div className="py-4 px-3">
          <div className="container-fluid">
            {props.children}
          </div>
        </div>
      </div>
      {showMenuMobile ? (<div className="backdrop" onClick={handleCloseMenuMobile}></div>) : null}
    </>
  )
}

export default withRouter(Layout);